var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.set.js");require("./medley.core.js");require("./metabase.lib.common.js");require("./metabase.lib.join.js");require("./metabase.lib.join.util.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.options.js");require("./metabase.lib.util.js");require("./metabase.mbql.util.match.js");require("./metabase.util.js");require("./metabase.util.malli.js");
'use strict';var E5,Hqa,Iqa,Jqa,G5,H5,I5,Kqa,Lqa,L5,J5,K5,M5,N5,Mqa,Nqa,Oqa,O5,Pqa,Qqa,P5,Rqa,Sqa;E5=function(a,b){if(b=$CLJS.y(b)){var c=$CLJS.y(b);b=$CLJS.A(c);c=$CLJS.B(c);return $CLJS.y(c)?(c=E5($CLJS.J.g(a,b),c),$CLJS.td(c)?$CLJS.Lk.g(a,b):$CLJS.R.j(a,b,c)):$CLJS.Lk.g(a,b)}return a};
Hqa=function(a,b,c,d){return function t(f,k,l,m){var u=$CLJS.y(k);k=$CLJS.A(u);u=$CLJS.B(u);var v=$CLJS.im(f,k);return $CLJS.n(v)?u?$CLJS.R.j(f,k,t($CLJS.Fb(v),u,l,m)):$CLJS.R.j(f,k,$CLJS.Se.j(l,$CLJS.Fb(v),m)):f}(a,b,c,d)};Iqa=function(a){for(var b=[],c=arguments.length,d=0;;)if(d<c)b.push(arguments[d]),d+=1;else break;return Hqa(arguments[0],arguments[1],arguments[2],3<b.length?new $CLJS.w(b.slice(3),0,null):null)};$CLJS.F5=function(a){return $CLJS.FE.h($CLJS.NE(a))};
Jqa=function(a,b,c,d){var e=$CLJS.E.g($CLJS.fQ,$CLJS.A(b))?$CLJS.GW(d,$CLJS.FW(c)):d;return Iqa(a,b,function(f){return $CLJS.Df(function(){return function m(l){return new $CLJS.ne(null,function(){for(;;){var t=$CLJS.y(l);if(t){if($CLJS.Ad(t)){var u=$CLJS.lc(t),v=$CLJS.D(u),x=$CLJS.qe(v);a:for(var z=0;;)if(z<v){var C=$CLJS.kd(u,z);C=$CLJS.E.g($CLJS.F5(C),$CLJS.F5(c))?e:C;x.add(C);z+=1}else{u=!0;break a}return u?$CLJS.te($CLJS.ve(x),m($CLJS.mc(t))):$CLJS.te($CLJS.ve(x),null)}x=$CLJS.A(t);return $CLJS.ee($CLJS.E.g($CLJS.F5(x),
$CLJS.F5(c))?e:x,m($CLJS.Lc(t)))}return null}},null,null)}(f)}())})};
G5=function(a,b,c,d){var e=$CLJS.FA(a,b);if($CLJS.n(e)){var f=$CLJS.F5(c),k=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.A(b),$CLJS.id(b)],null);c=$CLJS.I(k,0,null);k=$CLJS.I(k,1,null);e=$CLJS.eg.j($CLJS.Cf,$CLJS.Uk.h($CLJS.Tk.g($CLJS.ch([f]),$CLJS.F5)),e);e=$CLJS.E.g(b,new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.ZE],null))&&$CLJS.Qe(function(l){return $CLJS.OY(l,$CLJS.tB)},e)?null:e;if($CLJS.y(e))return $CLJS.cM(a,b,e);if($CLJS.E.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.KN,$CLJS.wL],null),new $CLJS.P(null,2,5,$CLJS.Q,
[c,k],null)))throw $CLJS.hi($CLJS.RE("Cannot remove the final join condition"),new $CLJS.h(null,5,[$CLJS.Hy,$CLJS.RW,$CLJS.wL,$CLJS.FA(a,b),$CLJS.SW,$CLJS.FA(a,$CLJS.sd(b)),$CLJS.JL,d,$CLJS.TW,a],null));return $CLJS.E.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.KN,$CLJS.ZE],null),new $CLJS.P(null,2,5,$CLJS.Q,[c,k],null))?$CLJS.DX.v(a,$CLJS.sd(b),$CLJS.Lk,k):E5(a,b)}return a};
H5=function(a,b){var c=$CLJS.e4.g(a,b),d=$CLJS.lh(0,$CLJS.D(c));return $CLJS.gf.l(new $CLJS.P(null,6,5,$CLJS.Q,[new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.VR],null),new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.dG],null),new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.pW],null),new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.ZE],null),new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.eG],null),new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.fQ],null)],null),function(){return function k(f){return new $CLJS.ne(null,function(){for(var l=f;;)if(l=$CLJS.y(l)){if($CLJS.Ad(l)){var m=
$CLJS.lc(l),t=$CLJS.D(m),u=$CLJS.qe(t);a:for(var v=0;;)if(v<t){var x=$CLJS.kd(m,v),z=$CLJS.F(c,x);$CLJS.ZE.h(z)instanceof $CLJS.M||u.add(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.KN,x,$CLJS.ZE],null));v+=1}else{m=!0;break a}return m?$CLJS.te($CLJS.ve(u),k($CLJS.mc(l))):$CLJS.te($CLJS.ve(u),null)}u=$CLJS.A(l);m=$CLJS.F(c,u);if($CLJS.ZE.h(m)instanceof $CLJS.M)l=$CLJS.Lc(l);else return $CLJS.ee(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.KN,u,$CLJS.ZE],null),k($CLJS.Lc(l)))}else return null},null,null)}(d)}(),$CLJS.H([function(){return function k(f){return new $CLJS.ne(null,
function(){for(;;){var l=$CLJS.y(f);if(l){if($CLJS.Ad(l)){var m=$CLJS.lc(l),t=$CLJS.D(m),u=$CLJS.qe(t);a:for(var v=0;;)if(v<t){var x=$CLJS.kd(m,v);u.add(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.KN,x,$CLJS.wL],null));v+=1}else{m=!0;break a}return m?$CLJS.te($CLJS.ve(u),k($CLJS.mc(l))):$CLJS.te($CLJS.ve(u),null)}u=$CLJS.A(l);return $CLJS.ee(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.KN,u,$CLJS.wL],null),k($CLJS.Lc(l)))}return null}},null,null)}(d)}()]))};
I5=function(a,b,c){var d=$CLJS.I(c,0,null),e=$CLJS.I(c,1,null);e=$CLJS.O(e);var f=$CLJS.J.g(e,$CLJS.vG),k=$CLJS.J.g(e,$CLJS.BR),l=$CLJS.I(c,2,null);return $CLJS.A($CLJS.CH(function(m){$CLJS.I(m,0,null);m=$CLJS.I(m,1,null);$CLJS.I(m,0,null);$CLJS.I(m,1,null);m=$CLJS.I(m,2,null);return $CLJS.E.g($CLJS.A(m),d)&&$CLJS.E.g($CLJS.vG.h($CLJS.hd(m)),f)&&$CLJS.E.g($CLJS.BR.h($CLJS.hd(m)),k)&&$CLJS.E.g($CLJS.id(m),l)},$CLJS.gm($CLJS.Ps,$CLJS.VR.h($CLJS.MW(a,b)))))};
Kqa=function(a,b,c,d){c=I5(a,b,c);return $CLJS.n(c)?$CLJS.XW.l(a,b,$CLJS.DX,$CLJS.H([new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.VR,c,2,1],null),$CLJS.Tk.g(function(e){return $CLJS.A1($CLJS.Dl,e)},$CLJS.kl),d])):a};Lqa=function(a,b,c){c=I5(a,b,c);return $CLJS.n(c)?$CLJS.XW.l(a,b,G5,$CLJS.H([new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.VR],null),$CLJS.FA($CLJS.MW(a,b),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.VR,c],null)),b])):a};
L5=function(a,b,c,d,e,f){var k=$CLJS.XW.l(a,b,f,$CLJS.H([d,e])),l=$CLJS.F5(e);return $CLJS.Rk.g(a,k)?$CLJS.A($CLJS.y($CLJS.lf($CLJS.Wa,function(m,t){try{if($CLJS.zd(t)&&1===$CLJS.D(t))try{var u=$CLJS.F(t,0);if($CLJS.he(u,$CLJS.fQ))return new $CLJS.P(null,1,5,$CLJS.Q,[function(){var K=$CLJS.FW(e);K=J5.W?J5.W(k,b,c,$CLJS.tB,$CLJS.N,K):J5.call(null,k,b,c,$CLJS.tB,$CLJS.N,K);return K5.v?K5.v(K,b,c,l):K5.call(null,K,b,c,l)}()],null);throw $CLJS.Y;}catch(K){if(K instanceof Error)if(m=K,m===$CLJS.Y)try{u=
$CLJS.F(t,0);if($CLJS.he(u,$CLJS.eG))return new $CLJS.P(null,1,5,$CLJS.Q,[function(){var S=J5.W?J5.W(k,b,c,$CLJS.eG,$CLJS.N,l):J5.call(null,k,b,c,$CLJS.eG,$CLJS.N,l);return K5.v?K5.v(S,b,c,l):K5.call(null,S,b,c,l)}()],null);throw $CLJS.Y;}catch(S){if(S instanceof Error){var v=S;if(v===$CLJS.Y)throw $CLJS.Y;throw v;}throw S;}else throw m;else throw K;}else throw $CLJS.Y;}catch(K){if(K instanceof Error)if(m=K,m===$CLJS.Y)try{if($CLJS.zd(t)&&1===$CLJS.D(t))try{var x=$CLJS.F(t,0);if($CLJS.he(x,$CLJS.dG))return new $CLJS.P(null,
1,5,$CLJS.Q,[K5.v?K5.v(k,b,c,l):K5.call(null,k,b,c,l)],null);throw $CLJS.Y;}catch(S){if(S instanceof Error)if(v=S,v===$CLJS.Y)try{x=$CLJS.F(t,0);if($CLJS.he(x,$CLJS.ZE))return new $CLJS.P(null,1,5,$CLJS.Q,[K5.v?K5.v(k,b,c,l):K5.call(null,k,b,c,l)],null);throw $CLJS.Y;}catch(V){if(V instanceof Error){var z=V;if(z===$CLJS.Y)throw $CLJS.Y;throw z;}throw V;}else throw v;else throw S;}else throw $CLJS.Y;}catch(S){if(S instanceof Error)if(v=S,v===$CLJS.Y)try{if($CLJS.zd(t)&&3===$CLJS.D(t))try{var C=$CLJS.F(t,
0);if($CLJS.he(C,$CLJS.KN))try{var G=$CLJS.F(t,2);if($CLJS.he(G,$CLJS.ZE))return new $CLJS.P(null,1,5,$CLJS.Q,[K5.v?K5.v(k,b,c,l):K5.call(null,k,b,c,l)],null);throw $CLJS.Y;}catch(V){if(V instanceof Error){z=V;if(z===$CLJS.Y)throw $CLJS.Y;throw z;}throw V;}else throw $CLJS.Y;}catch(V){if(V instanceof Error){z=V;if(z===$CLJS.Y)throw $CLJS.Y;throw z;}throw V;}else throw $CLJS.Y;}catch(V){if(V instanceof Error){z=V;if(z===$CLJS.Y)return new $CLJS.P(null,1,5,$CLJS.Q,[k],null);throw z;}throw V;}else throw v;
else throw S;}else throw m;else throw K;}}($CLJS.Cf,d)))):k};
J5=function(a,b,c,d,e,f){var k=$CLJS.MW(a,b),l=$CLJS.jf(function(m){var t=$CLJS.FA(k,m);return $CLJS.n(t)?$CLJS.fm(function(u){return $CLJS.A($CLJS.y($CLJS.lf($CLJS.Wa,function C(x,z){try{if($CLJS.zd(z)&&3===$CLJS.D(z))try{var G=$CLJS.F(z,0);if($CLJS.E.g(G,d))try{var K=$CLJS.F(z,1);if($CLJS.td(e)||$CLJS.z1($CLJS.fh(e),$CLJS.fh(K)))try{var S=$CLJS.F(z,2);if($CLJS.E.g(S,f))return new $CLJS.P(null,1,5,$CLJS.Q,[new $CLJS.P(null,2,5,$CLJS.Q,[m,u],null)],null);throw $CLJS.Y;}catch(Z){if(Z instanceof Error){var V=
Z;if(V===$CLJS.Y)throw $CLJS.Y;throw V;}throw Z;}else throw $CLJS.Y;}catch(Z){if(Z instanceof Error){V=Z;if(V===$CLJS.Y)throw $CLJS.Y;throw V;}throw Z;}else throw $CLJS.Y;}catch(Z){if(Z instanceof Error){V=Z;if(V===$CLJS.Y)throw $CLJS.Y;throw V;}throw Z;}else throw $CLJS.Y;}catch(Z){if(Z instanceof Error){V=Z;if(V===$CLJS.Y)return $CLJS.HV(C,x,z);throw V;}throw Z;}}($CLJS.Cf,u))))},t):null},$CLJS.H([H5(a,b)]));return $CLJS.fb(function(m,t){var u=$CLJS.I(t,0,null);t=$CLJS.I(t,1,null);return L5(m,b,
c,u,t,function(v,x,z){return G5(v,x,z,b)})},a,l)};K5=function(a,b,c,d){b=$CLJS.H2(c,b);if($CLJS.n(b)){var e=$CLJS.MW(c,b);c=$CLJS.Re(function(f){f=$CLJS.O(f);var k=$CLJS.J.g(f,$CLJS.WK),l=$CLJS.J.g(f,$CLJS.l1);return $CLJS.E.g($CLJS.FZ,k)&&$CLJS.E.g(d,l)?$CLJS.SZ.h(f):null},$CLJS.T0.j(c,b,e));return $CLJS.n(c)?J5(a,b,a,$CLJS.pG,$CLJS.N,c):a}return a};
M5=function(a,b,c,d,e){var f=$CLJS.yW.h(c),k=$CLJS.MW(a,b);c=$CLJS.CH(function(t){var u=$CLJS.FA(k,t);if($CLJS.n(u)){var v=$CLJS.F5(f);return $CLJS.n($CLJS.Re($CLJS.Tk.j($CLJS.ch([v]),$CLJS.FE,$CLJS.hd),u))?t:null}return null},H5(a,b));var l=(d=$CLJS.E.g($CLJS.NG,d))?$CLJS.yW.h(e):null;e=d?function(t,u,v){return Jqa(t,u,v,l)}:function(t,u,v){return G5(t,u,v,b)};var m=$CLJS.E.g(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.dG],null),c);a=d&&m&&$CLJS.E.g($CLJS.A(f),$CLJS.A(l))&&$CLJS.E.g($CLJS.id(f),$CLJS.id(l))?
Kqa(a,b,f,$CLJS.hm($CLJS.hd(l),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.BR,$CLJS.vG],null))):m?Lqa(a,b,f):a;return $CLJS.n(c)?L5(a,b,a,c,f,e):a};N5=function(a,b){return $CLJS.OY(a,$CLJS.pG)&&$CLJS.E.g($CLJS.M1(a),b)};Mqa=function(a,b,c){return function k(e,f){try{if(N5(f,b))return $CLJS.K3(f,c);throw $CLJS.Y;}catch(m){if(m instanceof Error){var l=m;if(l===$CLJS.Y)return $CLJS.fX(k,e,f);throw l;}throw m;}}($CLJS.Cf,a)};
Nqa=function(a,b,c){var d=$CLJS.KN.h(a),e=-1<b&&b<$CLJS.D(d)?new $CLJS.P(null,2,5,$CLJS.Q,[b,$CLJS.FA(d,new $CLJS.P(null,2,5,$CLJS.Q,[b,$CLJS.cF],null))],null):null;b=$CLJS.I(e,0,null);e=$CLJS.I(e,1,null);if($CLJS.n($CLJS.n(b)?$CLJS.Rk.g(e,c):b)){var f=$CLJS.PW();$CLJS.G3(f,$CLJS.hf.g($CLJS.cF,d));c=f(c);return Mqa($CLJS.cM(a,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.KN,b,$CLJS.cF],null),c),e,c)}return a};
Oqa=function(a,b,c){if($CLJS.Fd(c))return c;var d=function(){var e=$CLJS.ch([c]);return"string"===typeof c?$CLJS.Tk.g(e,$CLJS.cF):e}();return $CLJS.Re(function(e){var f=$CLJS.I(e,0,null);e=$CLJS.I(e,1,null);return $CLJS.n(d.h?d.h(e):d.call(null,e))?f:null},$CLJS.gm($CLJS.Ps,$CLJS.KN.h($CLJS.MW(a,b))))};
O5=function(a,b,c,d){b=$CLJS.ku.g($CLJS.fh($CLJS.T0.j(b,c,$CLJS.MW(b,c))),$CLJS.fh($CLJS.T0.j(a,c,$CLJS.MW(a,c))));return $CLJS.fb(function(e,f){return $CLJS.Se.N(J5,e,c,a,d.h?d.h(f):d.call(null,f))},a,b)};Pqa=function(a,b,c){a=O5(a,b,c,function(d){return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.pG,new $CLJS.h(null,1,[$CLJS.AP,$CLJS.g_.h(d)],null),$CLJS.$i.h(d)],null)});c=$CLJS.H2(a,c);return $CLJS.n(c)?O5(a,b,c,function(d){return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.pG,$CLJS.N,$CLJS.SZ.h(d)],null)}):a};
Qqa=function(a,b,c){return $CLJS.Fd(c)?$CLJS.FA($CLJS.MW(a,b),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.KN,c,$CLJS.cF],null)):$CLJS.xd(c)?$CLJS.cF.h(c):c};P5=function(a,b,c,d){var e=Qqa(a,b,c);return $CLJS.n(e)?(c=function(){var f=(0,$CLJS.XW)(a,b,function(k){var l=$CLJS.KN.h(k);l=d.g?d.g(l,e):d.call(null,l,e);return $CLJS.ME(k,$CLJS.KN,l)});return(0,$CLJS.XW)(f,b,function(k){return $CLJS.aX(k,$CLJS.KN,function(l){return $CLJS.Vk.g(function(m){return $CLJS.S3(f,b,m)},l)})})}(),Pqa(c,a,b)):a};
Rqa=function(a,b){return null!=$CLJS.A($CLJS.y($CLJS.lf($CLJS.Wa,function f(d,e){try{if(N5(e,b))return new $CLJS.P(null,1,5,$CLJS.Q,[e],null);throw $CLJS.Y;}catch(l){if(l instanceof Error){var k=l;if(k===$CLJS.Y)return $CLJS.HV(f,d,e);throw k;}throw l;}}($CLJS.Cf,a))))};Sqa=new $CLJS.M(null,"remove","remove",-131428414);$CLJS.R5=function(){function a(d,e,f){return $CLJS.xd(f)&&$CLJS.E.g($CLJS.cu.h(f),$CLJS.oL)?$CLJS.Q5.j?$CLJS.Q5.j(d,e,f):$CLJS.Q5.call(null,d,e,f):M5(d,e,f,Sqa,null)}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();
$CLJS.T5=function(){function a(d,e,f,k){return $CLJS.xd(f)&&$CLJS.E.g($CLJS.cu.h(f),$CLJS.oL)?$CLJS.S5.v?$CLJS.S5.v(d,e,f,k):$CLJS.S5.call(null,d,e,f,k):M5(d,e,f,$CLJS.NG,k)}function b(d,e,f){return c.v?c.v(d,-1,e,f):c.call(null,d,-1,e,f)}var c=null;c=function(d,e,f,k){switch(arguments.length){case 3:return b.call(this,d,e,f);case 4:return a.call(this,d,e,f,k)}throw Error("Invalid arity: "+arguments.length);};c.j=b;c.v=a;return c}();
$CLJS.Tqa=function(){function a(d,e,f,k){f=Oqa(d,e,f);return $CLJS.n(f)?$CLJS.XW.l(d,e,Nqa,$CLJS.H([f,k])):d}function b(d,e,f){return c.v?c.v(d,-1,e,f):c.call(null,d,-1,e,f)}var c=null;c=function(d,e,f,k){switch(arguments.length){case 3:return b.call(this,d,e,f);case 4:return a.call(this,d,e,f,k)}throw Error("Invalid arity: "+arguments.length);};c.j=b;c.v=a;return c}();
$CLJS.Q5=function(){function a(d,e,f){try{return P5(d,e,f,function(u,v){return $CLJS.Fe($CLJS.g1(function(x){return!($CLJS.E.g($CLJS.cF.h(x),v)||Rqa(x,v))},u))})}catch(u){var k=u,l=$CLJS.O($CLJS.km(k)),m=$CLJS.J.g(l,$CLJS.JL),t=$CLJS.J.g(l,$CLJS.Hy);l=$CLJS.J.g(l,$CLJS.SW);if($CLJS.E.g(t,$CLJS.RW))return d=c.j?c.j(d,m,l):c.call(null,d,m,l),c.j?c.j(d,e,f):c.call(null,d,e,f);throw k;}}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,
d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();
$CLJS.S5=function(){function a(d,e,f,k){return null==k?$CLJS.Q5.j(d,e,f):P5(d,e,f,function(l,m){return $CLJS.Vk.g(function(t){return $CLJS.E.g($CLJS.cF.h(t),m)?k:t},l)})}function b(d,e,f){return c.v?c.v(d,-1,e,f):c.call(null,d,-1,e,f)}var c=null;c=function(d,e,f,k){switch(arguments.length){case 3:return b.call(this,d,e,f);case 4:return a.call(this,d,e,f,k)}throw Error("Invalid arity: "+arguments.length);};c.j=b;c.v=a;return c}();