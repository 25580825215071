var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.set.js");require("./metabase.util.malli.registry.js");
'use strict';var vfa,wfa,xfa,yfa,zfa,Afa,Bfa,Cfa,Dfa;vfa=function(a,b){a.sort(b||$CLJS.Da)};wfa=function(a,b){const c=Array(a.length);for(let e=0;e<a.length;e++)c[e]={index:e,value:a[e]};const d=b||$CLJS.Da;vfa(c,function(e,f){return d(e.value,f.value)||e.index-f.index});for(b=0;b<a.length;b++)a[b]=c[b].value};
$CLJS.$G=function(a){return $CLJS.E.g(a,$CLJS.Jd)?$CLJS.Jd:function(b,c){var d=a.g?a.g(b,c):a.call(null,b,c);return"number"===typeof d?d:$CLJS.n(d)?-1:$CLJS.n(a.g?a.g(c,b):a.call(null,c,b))?1:0}};$CLJS.aH=function(a,b){if($CLJS.y(b)){var c=$CLJS.we.h?$CLJS.we.h(b):$CLJS.we.call(null,b);wfa(c,$CLJS.$G(a));return $CLJS.od($CLJS.y(c),$CLJS.pd(b))}return $CLJS.Kc};$CLJS.bH=new $CLJS.M(null,"second","second",-444702010);$CLJS.cH=new $CLJS.M(null,"second-of-minute","second-of-minute",222734326);
$CLJS.dH=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.date-time","metabase.lib.schema.temporal-bucketing/unit.date-time",1924841408);$CLJS.eH=new $CLJS.M(null,"millisecond","millisecond",-540123566);$CLJS.fH=new $CLJS.M(null,"year-of-era","year-of-era",682445876);xfa=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.date-time.extract","metabase.lib.schema.temporal-bucketing/unit.date-time.extract",1230935001);
yfa=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.time","metabase.lib.schema.temporal-bucketing/unit.time",713582593);$CLJS.gH=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.date-time.truncate","metabase.lib.schema.temporal-bucketing/unit.date-time.truncate",686665771);$CLJS.hH=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.time.interval","metabase.lib.schema.temporal-bucketing/unit.time.interval",-718964325);
$CLJS.iH=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.date","metabase.lib.schema.temporal-bucketing/unit.date",1876937510);zfa=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.time.extract","metabase.lib.schema.temporal-bucketing/unit.time.extract",-456342719);Afa=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.date.truncate","metabase.lib.schema.temporal-bucketing/unit.date.truncate",1199064479);
Bfa=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.date.extract","metabase.lib.schema.temporal-bucketing/unit.date.extract",1595055425);Cfa=new $CLJS.M("metabase.lib.schema.temporal-bucketing","option","metabase.lib.schema.temporal-bucketing/option",-1756789401);$CLJS.jH=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.date-time.interval","metabase.lib.schema.temporal-bucketing/unit.date-time.interval",659301368);
$CLJS.kH=new $CLJS.M("option","temporal-bucketing","option/temporal-bucketing",-108914562);$CLJS.lH=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit","metabase.lib.schema.temporal-bucketing/unit",1808010880);$CLJS.mH=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.date.interval","metabase.lib.schema.temporal-bucketing/unit.date.interval",-1463811525);
Dfa=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.time.truncate","metabase.lib.schema.temporal-bucketing/unit.time.truncate",-948820335);var nH=new $CLJS.P(null,8,5,$CLJS.Q,[$CLJS.Hi,$CLJS.Hj,$CLJS.Fj,$CLJS.pi,$CLJS.ok,$CLJS.Bi,$CLJS.ji,$CLJS.fH],null),oH=$CLJS.fh(nH),Efa,Ffa,Gfa;$CLJS.X(Bfa,$CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pk,new $CLJS.h(null,1,[$CLJS.vt,"Valid date extraction unit"],null)],null),oH));var pH=new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.du,$CLJS.pj,$CLJS.yj,$CLJS.oi,$CLJS.ji],null),qH=$CLJS.fh(pH);
$CLJS.X(Afa,$CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pk,new $CLJS.h(null,1,[$CLJS.vt,"Valid date truncation unit"],null)],null),qH));$CLJS.rH=$CLJS.eg.j($CLJS.Cf,$CLJS.nl.o(),$CLJS.gf.g(pH,nH));Efa=$CLJS.fh($CLJS.rH);$CLJS.X($CLJS.iH,$CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pk,new $CLJS.h(null,1,[$CLJS.vt,"Valid date bucketing unit"],null)],null),Efa));var sH=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.cH,$CLJS.jj,$CLJS.vi],null),tH=$CLJS.fh(sH);
$CLJS.X(zfa,$CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pk,new $CLJS.h(null,1,[$CLJS.vt,"Valid time extraction unit"],null)],null),tH));var uH=new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.eH,$CLJS.bH,$CLJS.Vi,$CLJS.uk],null),vH=$CLJS.fh(uH);$CLJS.X(Dfa,$CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pk,new $CLJS.h(null,1,[$CLJS.vt,"Valid time truncation unit"],null)],null),vH));$CLJS.wH=$CLJS.eg.j($CLJS.Cf,$CLJS.nl.o(),$CLJS.gf.g(uH,sH));Ffa=$CLJS.fh($CLJS.wH);
$CLJS.X(yfa,$CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pk,new $CLJS.h(null,1,[$CLJS.vt,"Valid time bucketing unit"],null)],null),Ffa));$CLJS.xH=$CLJS.eg.j($CLJS.Cf,$CLJS.nl.o(),$CLJS.gf.l(uH,pH,$CLJS.H([sH,nH])));Gfa=$CLJS.fh($CLJS.xH);$CLJS.X($CLJS.dH,$CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pk,new $CLJS.h(null,1,[$CLJS.vt,"Valid datetime bucketing unit"],null)],null),$CLJS.xH));var Hfa=$CLJS.be.g(Gfa,$CLJS.ci);
$CLJS.X($CLJS.lH,$CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pk,new $CLJS.h(null,1,[$CLJS.vt,"Valid temporal bucketing unit"],null)],null),Hfa));$CLJS.yH=$CLJS.eu.g(qH,vH);$CLJS.X($CLJS.gH,$CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pk,new $CLJS.h(null,1,[$CLJS.vt,"Valid datetime truncation unit"],null)],null),$CLJS.yH));$CLJS.zH=$CLJS.eu.g(oH,tH);$CLJS.X(xfa,$CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pk,new $CLJS.h(null,1,[$CLJS.vt,"Valid datetime extraction unit"],null)],null),$CLJS.zH));
var AH=$CLJS.be.g(qH,$CLJS.ji);$CLJS.X($CLJS.mH,$CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pk,new $CLJS.h(null,1,[$CLJS.vt,"Valid date interval unit"],null)],null),AH));$CLJS.X($CLJS.hH,$CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pk,new $CLJS.h(null,1,[$CLJS.vt,"Valid time interval unit"],null)],null),vH));var Ifa=$CLJS.eu.g(AH,vH);$CLJS.X($CLJS.jH,$CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pk,new $CLJS.h(null,1,[$CLJS.vt,"Valid datetime interval unit"],null)],null),Ifa));
$CLJS.X(Cfa,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.kk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cu,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cs,$CLJS.kH],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ri,$CLJS.lH],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ci,new $CLJS.h(null,1,[$CLJS.Qs,!0],null),$CLJS.Ls],null)],null));