var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./medley.core.js");require("./metabase.lib.common.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.metadata.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.options.js");require("./metabase.lib.ref.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.lib.temporal_bucket.js");require("./metabase.lib.util.js");require("./metabase.shared.util.i18n.js");require("./metabase.types.js");require("./metabase.util.js");require("./metabase.util.malli.js");
'use strict';var i1,Ina,Jna,j1,k1;$CLJS.g1=function(a,b){return $CLJS.gc($CLJS.fb(function(c,d){return $CLJS.n(a.h?a.h(d):a.call(null,d))?$CLJS.fc(c,d):c},$CLJS.ec($CLJS.Cf),b))};i1=function(a){return $CLJS.n(h1)?["(",$CLJS.p.h(a),")"].join(""):a};Ina=function(a,b,c){var d=$CLJS.MW(a,b);a=$CLJS.T0.j(a,b,d);var e=$CLJS.cB(c);return $CLJS.Re(function(f){return $CLJS.E.g($CLJS.cB($CLJS.T.h(f)),e)},a)};
Jna=function(a,b){var c=$CLJS.Wk.v(a,$CLJS.fQ,$CLJS.YD($CLJS.be,$CLJS.Cf),b);return $CLJS.zd($CLJS.ZE.h(a))?$CLJS.Wk.v(c,$CLJS.ZE,$CLJS.be,$CLJS.wW(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.tB,$CLJS.N,$CLJS.FW(b)],null))):c};j1=function(a,b,c){var d=$CLJS.FW(c);return $CLJS.R.l($CLJS.h0.j(a,b,c),$CLJS.WK,$CLJS.MZ,$CLJS.H([$CLJS.T,d,$CLJS.tE,d]))};k1=new $CLJS.M("metabase.lib.expression","infix-operator","metabase.lib.expression/infix-operator",246733613);
$CLJS.l1=new $CLJS.M("lib","source-uuid","lib/source-uuid",1828897581);var m1=function(){function a(d,e,f){var k=$CLJS.MW(d,e);k=$CLJS.CH($CLJS.Tk.g($CLJS.ch([f]),$CLJS.FW),$CLJS.fQ.h(k));if($CLJS.n(k))return k;throw $CLJS.hi($CLJS.mE("No expression named {0}",$CLJS.H([$CLJS.Mh.l($CLJS.H([f]))])),new $CLJS.h(null,3,[$CLJS.hS,f,$CLJS.MF,d,$CLJS.JL,e],null));}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);
};c.g=b;c.j=a;return c}();$CLJS.M0.m(null,$CLJS.tB,function(a,b,c){$CLJS.I(c,0,null);$CLJS.I(c,1,null);c=$CLJS.I(c,2,null);c=m1.j(a,b,c);return $CLJS.N0.j(a,b,c)});$CLJS.O0.m(null,$CLJS.tB,function(a,b,c){$CLJS.I(c,0,null);var d=$CLJS.I(c,1,null),e=$CLJS.I(c,2,null);return new $CLJS.h(null,7,[$CLJS.cu,$CLJS.VK,$CLJS.l1,$CLJS.FE.h(d),$CLJS.T,e,$CLJS.xE,e,$CLJS.tE,$CLJS.i0.j(a,b,c),$CLJS.wB,$CLJS.N0.j(a,b,c),$CLJS.WK,$CLJS.MZ],null)});$CLJS.I0.m(null,$CLJS.nB,function(a,b,c){return $CLJS.p.h(c)});
$CLJS.I0.m(null,$CLJS.lB,function(a,b,c){return $CLJS.p.h(c)});$CLJS.I0.m(null,$CLJS.mB,function(a,b,c){return['"',$CLJS.p.h(c),'"'].join("")});$CLJS.I0.m(null,$CLJS.CB,function(a,b,c){return $CLJS.p.h(c)});$CLJS.I0.m(null,$CLJS.tB,function(a,b,c){$CLJS.I(c,0,null);$CLJS.I(c,1,null);return $CLJS.I(c,2,null)});$CLJS.J0.m(null,$CLJS.tB,function(a,b,c){$CLJS.I(c,0,null);$CLJS.I(c,1,null);return $CLJS.I(c,2,null)});
for(var h1=!1,Kna=new $CLJS.h(null,4,[$CLJS.es,"+",$CLJS.ot,"-",$CLJS.fs,"×",$CLJS.KG,"÷"],null),n1=$CLJS.y(new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.es,$CLJS.ot,$CLJS.KG,$CLJS.fs],null)),o1=null,p1=0,q1=0;;)if(q1<p1){var Lna=o1.X(null,q1);$CLJS.xF(Lna,k1);q1+=1}else{var r1=$CLJS.y(n1);if(r1){var s1=r1;if($CLJS.Ad(s1)){var t1=$CLJS.lc(s1),Mna=$CLJS.mc(s1),Nna=t1,Ona=$CLJS.D(t1);n1=Mna;o1=Nna;p1=Ona}else{var Pna=$CLJS.A(s1);$CLJS.xF(Pna,k1);n1=$CLJS.B(s1);o1=null;p1=0}q1=0}else break}
$CLJS.I0.m(null,k1,function(a,b,c){var d=$CLJS.y(c);c=$CLJS.A(d);d=$CLJS.B(d);$CLJS.A(d);d=$CLJS.B(d);c=$CLJS.J.g(Kna,c);a:{var e=h1;h1=!0;try{var f=$CLJS.Gt([" ",$CLJS.gh(c)," "].join(""),$CLJS.hf.g($CLJS.fu($CLJS.i0,a,b),d));break a}finally{h1=e}f=void 0}return i1(f)});$CLJS.J0.m(null,k1,function(){return"expression"});
$CLJS.M0.m(null,$CLJS.JH,function(a,b,c){c=$CLJS.y(c);$CLJS.A(c);c=$CLJS.B(c);$CLJS.A(c);var d=$CLJS.B(c);return $CLJS.Qd($CLJS.zF,function(){return function k(f){return new $CLJS.ne(null,function(){for(;;){var l=$CLJS.y(f);if(l){if($CLJS.Ad(l)){var m=$CLJS.lc(l),t=$CLJS.D(m),u=$CLJS.qe(t);a:for(var v=0;;)if(v<t){var x=$CLJS.kd(m,v);x=$CLJS.N0.j(a,b,x);u.add(x);v+=1}else{m=!0;break a}return m?$CLJS.te($CLJS.ve(u),k($CLJS.mc(l))):$CLJS.te($CLJS.ve(u),null)}u=$CLJS.A(l);return $CLJS.ee($CLJS.N0.j(a,
b,u),k($CLJS.Lc(l)))}return null}},null,null)}(d)}())});$CLJS.I0.m(null,$CLJS.qJ,function(a,b,c,d){$CLJS.I(c,0,null);$CLJS.I(c,1,null);var e=$CLJS.I(c,2,null),f=$CLJS.I(c,3,null);c=$CLJS.I(c,4,null);a=$CLJS.p.h($CLJS.i0.v(a,b,e,d));b=$CLJS.p;d=b.h;c=$CLJS.a1.g(f,c).toLowerCase();0<f?f=$CLJS.fa.j?$CLJS.fa.j("+ %d %s",f,c):$CLJS.fa.call(null,"+ %d %s",f,c):(f=$CLJS.DA(f),f=$CLJS.fa.j?$CLJS.fa.j("- %d %s",f,c):$CLJS.fa.call(null,"- %d %s",f,c));f=i1(f);return[a," ",d.call(b,f)].join("")});
$CLJS.J0.m(null,$CLJS.qJ,function(a,b,c){$CLJS.I(c,0,null);$CLJS.I(c,1,null);var d=$CLJS.I(c,2,null),e=$CLJS.I(c,3,null);c=$CLJS.I(c,4,null);a=$CLJS.p.h($CLJS.K0.j(a,b,d));b=$CLJS.p;d=b.h;c=$CLJS.a1.g(e,c).toLowerCase();0<e?e=$CLJS.fa.j?$CLJS.fa.j("plus_%s_%s",e,c):$CLJS.fa.call(null,"plus_%s_%s",e,c):(e=$CLJS.DA(e),e=$CLJS.fa.j?$CLJS.fa.j("minus_%d_%s",e,c):$CLJS.fa.call(null,"minus_%d_%s",e,c));return[a,"_",d.call(b,e)].join("")});
$CLJS.I0.m(null,$CLJS.sG,function(a,b,c,d){$CLJS.I(c,0,null);$CLJS.I(c,1,null);var e=$CLJS.I(c,2,null);$CLJS.I(c,3,null);return $CLJS.i0.v(a,b,e,d)});$CLJS.J0.m(null,$CLJS.sG,function(a,b,c){$CLJS.I(c,0,null);$CLJS.I(c,1,null);var d=$CLJS.I(c,2,null);$CLJS.I(c,3,null);return $CLJS.K0.j(a,b,d)});
$CLJS.Qna=function(){function a(d,e,f,k){e=$CLJS.n(e)?e:-1;if($CLJS.n(Ina(d,e,f)))throw $CLJS.hi("Expression name conflicts with a column in the same query stage",new $CLJS.h(null,1,[$CLJS.hS,f],null));return $CLJS.XW.l(d,e,Jna,$CLJS.H([$CLJS.GW($CLJS.yW.h(k),f)]))}function b(d,e,f){return c.v?c.v(d,-1,e,f):c.call(null,d,-1,e,f)}var c=null;c=function(d,e,f,k){switch(arguments.length){case 3:return b.call(this,d,e,f);case 4:return a.call(this,d,e,f,k)}throw Error("Invalid arity: "+arguments.length);
};c.j=b;c.v=a;return c}();$CLJS.Rna=function(){function a(d,e){var f=$CLJS.Fe($CLJS.fQ.h($CLJS.MW(d,e)));return null==f?null:$CLJS.Vk.g($CLJS.fu(j1,d,e),f)}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.u1=function(){function a(d,e){return $CLJS.Fe($CLJS.fQ.h($CLJS.MW(d,e)))}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();$CLJS.vW.m(null,$CLJS.tB,function(a){return a});
$CLJS.Sna=function(){function a(d,e,f){var k=$CLJS.eg.g($CLJS.N,$CLJS.gm(function(m,t){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.FW(t),m],null)},$CLJS.u1.g(d,e))),l=$CLJS.MW(d,e);d=$CLJS.T0.j(d,e,l);return $CLJS.Fe($CLJS.g1(function(m){return $CLJS.Va(f)||$CLJS.Rk.g($CLJS.WK.h(m),$CLJS.MZ)||$CLJS.J.g(k,$CLJS.T.h(m))<f},d))}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+
arguments.length);};c.g=b;c.j=a;return c}();$CLJS.Tna=function(){function a(d,e,f){return $CLJS.xW(j1(d,e,m1.j(d,e,f)))}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();