var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.set.js");require("./clojure.walk.js");require("./medley.core.js");require("./metabase.mbql.util.js");require("./metabase.mbql.util.match.js");require("./metabase.shared.util.i18n.js");require("./metabase.util.log.js");require("./metabase.util.malli.js");
'use strict';var cX,gla,eX,gX,hX,hla,jX,kX,bX,lX,ila,jla,kla,lla,oX,pX,rX,sX,tX,uX,vX,wX,dX,nla,ola,zX,pla,AX,qla,CX,rla,sla,tla,ula,vla,ZW;$CLJS.$W=function(a,b){if("string"===typeof b)return ZW(a,b);throw new TypeError("re-seq must match against a string.");};$CLJS.aX=function(a,b,c){var d=$CLJS.im(a,b);if($CLJS.n(d)){var e=$CLJS.R.j;d=$CLJS.Fb(d);c=c.h?c.h(d):c.call(null,d);a=e.call($CLJS.R,a,b,c)}return a};
cX=function(a){var b=bX;return $CLJS.KA(function(c){return function(d,e,f){e=b.h?b.h(e):b.call(null,e);return c.j?c.j(d,e,f):c.call(null,d,e,f)}},a)};gla=function(a){var b=dX;return $CLJS.KA(function(c){return function(d,e,f){f=b.h?b.h(f):b.call(null,f);return c.j?c.j(d,e,f):c.call(null,d,e,f)}},a)};eX=function(a){if($CLJS.md(a))return a;throw $CLJS.hi("Invalid pattern: don't know how to handle symbol.",new $CLJS.h(null,1,[$CLJS.Ii,a],null));};
$CLJS.fX=function(a,b,c){return $CLJS.xd(c)?$CLJS.eg.g(c,function(){return function f(e){return new $CLJS.ne(null,function(){for(;;){var k=$CLJS.y(e);if(k){if($CLJS.Ad(k)){var l=$CLJS.lc(k),m=$CLJS.D(l),t=$CLJS.qe(m);return function(){for(var z=0;;)if(z<m){var C=$CLJS.kd(l,z),G=$CLJS.I(C,0,null),K=$CLJS.I(C,1,null);C=t;var S=$CLJS.Q,V=G;G=$CLJS.be.g(b,G);G=a.g?a.g(G,K):a.call(null,G,K);C.add(new $CLJS.P(null,2,5,S,[V,G],null));z+=1}else return!0}()?$CLJS.te($CLJS.ve(t),f($CLJS.mc(k))):$CLJS.te($CLJS.ve(t),
null)}var u=$CLJS.A(k),v=$CLJS.I(u,0,null),x=$CLJS.I(u,1,null);return $CLJS.ee(new $CLJS.P(null,2,5,$CLJS.Q,[v,function(){var z=$CLJS.be.g(b,v),C=x;return a.g?a.g(z,C):a.call(null,z,C)}()],null),f($CLJS.Lc(k)))}return null}},null,null)}(c)}()):$CLJS.wd(c)?$CLJS.Vk.g($CLJS.Ve(a,$CLJS.A(c)instanceof $CLJS.M?$CLJS.be.g(b,$CLJS.A(c)):b),c):c};gX=function(a){var b=(b=a instanceof $CLJS.M)?$CLJS.ie(a):b;return $CLJS.n(b)?[$CLJS.ie(a),"/",$CLJS.gh(a)].join(""):$CLJS.gh(a)};
hX=function(a,b){var c=$CLJS.wd(b)&&!$CLJS.zf(b)&&$CLJS.A(b)instanceof $CLJS.M;return c?$CLJS.ud(a)?(b=$CLJS.A(b),a=$CLJS.fh(a),a.h?a.h(b):a.call(null,b)):$CLJS.E.g(a,$CLJS.A(b)):c};
hla=function(a,b){return $CLJS.jf(function(c){var d=$CLJS.lf;a:try{if($CLJS.zd(c)&&1<=$CLJS.D(c))try{var e=$CLJS.$k.j(c,0,1);if($CLJS.zd(e)&&1===$CLJS.D(e))try{var f=$CLJS.F(e,0);if($CLJS.n($CLJS.Ve($CLJS.E,a)(f))){var k=$CLJS.$k.g(c,1);var l=new $CLJS.P(null,1,5,$CLJS.Q,[k],null)}else throw $CLJS.Y;}catch(t){if(t instanceof Error){var m=t;if(m===$CLJS.Y)throw $CLJS.Y;throw m;}throw t;}else throw $CLJS.Y;}catch(t){if(t instanceof Error){m=t;if(m===$CLJS.Y)throw $CLJS.Y;throw m;}throw t;}else throw $CLJS.Y;
}catch(t){if(t instanceof Error){m=t;if(m===$CLJS.Y){l=new $CLJS.P(null,1,5,$CLJS.Q,[new $CLJS.P(null,1,5,$CLJS.Q,[c],null)],null);break a}throw m;}throw t;}return $CLJS.A($CLJS.y(d($CLJS.Wa,l)))},$CLJS.H([b]))};
jX=function(a,b){for(;;)switch(b=$CLJS.nl.h($CLJS.lf($CLJS.Wa,b)),$CLJS.D(b)){case 0:return null;case 1:return a=$CLJS.A(b),iX.h?iX.h(a):iX.call(null,a);default:if($CLJS.n($CLJS.Re($CLJS.Ve(hX,a),b))){var c=a;b=hla(a,b);a=c}else{c=$CLJS.hf.g(iX,b);if($CLJS.E.g(c,b))return $CLJS.eg.g(new $CLJS.P(null,1,5,$CLJS.Q,[a],null),b);b=c}}};kX=function(a){var b=$CLJS.wd(a);return b?(b=!$CLJS.zf(a))?$CLJS.EA($CLJS.ge,$CLJS.Ya)($CLJS.A(a)):b:b};
bX=function(a){return $CLJS.n($CLJS.EA($CLJS.ge,$CLJS.Ya)(a))?$CLJS.zh.h($CLJS.IA(gX(a).toLowerCase(),/_/,"-")):a};lX=function(a,b){var c=kX(b);return $CLJS.n(c)?(b=bX($CLJS.A(b)),$CLJS.ud(a)?(a=$CLJS.fh(a),a.h?a.h(b):a.call(null,b)):$CLJS.E.g(a,b)):c};ila=function(a){return $CLJS.J.j(new $CLJS.h(null,3,[$CLJS.ci,$CLJS.Td,$CLJS.nj,bX,$CLJS.XN,bX],null),a,function(b){var c=$CLJS.J,d=c.g;b=$CLJS.Ee([a,b]);b=mX.g?mX.g(b,nX):mX.call(null,b,nX);return d.call(c,b,a)})};
jla=function(a){a=$CLJS.eg.j($CLJS.N,$CLJS.hf.h(function(b){var c=$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);c=bX(c);var d=ila(c);return new $CLJS.P(null,2,5,$CLJS.Q,[c,d.h?d.h(b):d.call(null,b)],null)}),a);return $CLJS.E.g($CLJS.nj.h(a),$CLJS.JF)&&$CLJS.Va($CLJS.XN.h(a))?$CLJS.R.j(a,$CLJS.XN,$CLJS.IG):a};kla=function(a){return $CLJS.eg.j($CLJS.N,$CLJS.hf.h(function(b){var c=$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);c=gX(c);return new $CLJS.P(null,2,5,$CLJS.Q,[c,$CLJS.R.j(jla(b),$CLJS.T,c)],null)}),a)};
lla=function(a){a=mX.h?mX.h(a):mX.call(null,a);return dX.h?dX.h(a):dX.call(null,a)};oX=function(a){return $CLJS.aX($CLJS.aX($CLJS.fb(function(b,c){return $CLJS.aX(b,c,$CLJS.zh)},a,new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.jD,$CLJS.Fea,$CLJS.KO,mla,$CLJS.eA,$CLJS.ri],null)),$CLJS.jC,lla),$CLJS.ZN,$CLJS.CV)};pX=function(a){return $CLJS.Fd(a)?new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.pG,a,null],null):a};rX=function(a){return qX.h(pX(a))};
sX=function(a){var b=$CLJS.y(a);a=$CLJS.A(b);b=$CLJS.B(b);return iX($CLJS.eg.g(new $CLJS.P(null,1,5,$CLJS.Q,[a],null),$CLJS.hf.g(qX,b)))};tX=function(a){var b=$CLJS.y(a);a=$CLJS.A(b);var c=$CLJS.B(b);b=$CLJS.A(c);c=$CLJS.B(c);return $CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[a,rX(b)],null),$CLJS.hf.g(qX,c))};uX=function(a){var b=$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return $CLJS.n(a)?new $CLJS.P(null,2,5,$CLJS.Q,[b,rX(a)],null):new $CLJS.P(null,1,5,$CLJS.Q,[b],null)};
vX=function(a){var b=$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return new $CLJS.P(null,2,5,$CLJS.Q,[b,rX(a)],null)};wX=function(a){var b=$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return new $CLJS.P(null,2,5,$CLJS.Q,[b,qX.h(a)],null)};
dX=function(a){return xX(function(b){if($CLJS.xd(b))return gla(b);if($CLJS.Va(kX(b)))return b;try{return qX.h(b)}catch(f){if(f instanceof Error){var c=f,d=$CLJS.OA($CLJS.Hy);if($CLJS.n($CLJS.NA("metabase.mbql.normalize",d))){var e=$CLJS.RE("Invalid clause:");e instanceof Error?$CLJS.MA("metabase.mbql.normalize",d,$CLJS.Fw.l($CLJS.H([b])),e):$CLJS.MA("metabase.mbql.normalize",d,$CLJS.Fw.l($CLJS.H([e,b])),null)}throw $CLJS.gi($CLJS.mE("Invalid MBQL clause: {0}",$CLJS.H([$CLJS.zV(c)])),new $CLJS.h(null,
1,[$CLJS.gS,b],null),c);}throw f;}},a)};nla=function(a){return $CLJS.Df($CLJS.fm(dX,yX(a)))};ola=function(a){for(;;)if($CLJS.n(kX(a)))a=new $CLJS.P(null,1,5,$CLJS.Q,[a],null);else return $CLJS.Fe($CLJS.Vk.g(pX,a))};zX=function(a){a=$CLJS.O(a);var b=$CLJS.J.g(a,$CLJS.JF);return $CLJS.n(b)?$CLJS.Wk.j(a,$CLJS.JF,qX):a};
pla=function(a){return $CLJS.eg.g($CLJS.N,function(){return function d(c){return new $CLJS.ne(null,function(){for(;;){var e=$CLJS.y(c);if(e){if($CLJS.Ad(e)){var f=$CLJS.lc(e),k=$CLJS.D(f),l=$CLJS.qe(k);a:for(var m=0;;)if(m<k){var t=$CLJS.kd(f,m),u=$CLJS.I(t,0,null);t=$CLJS.I(t,1,null);u=new $CLJS.P(null,2,5,$CLJS.Q,[u,zX(t)],null);l.add(u);m+=1}else{f=!0;break a}return f?$CLJS.te($CLJS.ve(l),d($CLJS.mc(e))):$CLJS.te($CLJS.ve(l),null)}f=$CLJS.A(e);l=$CLJS.I(f,0,null);f=$CLJS.I(f,1,null);return $CLJS.ee(new $CLJS.P(null,
2,5,$CLJS.Q,[l,zX(f)],null),d($CLJS.Lc(e)))}return null}},null,null)}(a)}())};AX=function(a){a=$CLJS.O(a);var b=$CLJS.J.g(a,$CLJS.NQ);return $CLJS.n(b)?$CLJS.Wk.j(a,$CLJS.NQ,pla):a};qla=function(a){var b=$CLJS.O(a);a=$CLJS.J.g(b,$CLJS.qQ);b=$CLJS.Va(a)?BX.h?BX.h(b):BX.call(null,b):b;return $CLJS.n(a)?AX(b):b};CX=function(a){return $CLJS.ud(a)?$CLJS.y(a):null!=a};
rla=function(a){var b=$CLJS.O(a);a=$CLJS.J.g(b,$CLJS.MF);var c=$CLJS.J.g(b,$CLJS.BN),d=$CLJS.J.g(b,$CLJS.YO),e=$CLJS.J.g(b,$CLJS.qQ);try{if($CLJS.n(d)){var f=$CLJS.O(b),k=$CLJS.J.g(f,$CLJS.YO);var l=$CLJS.cM($CLJS.Lk.g(f,$CLJS.YO),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.MF,$CLJS.YO],null),k)}else l=b;var m=$CLJS.n(a)?$CLJS.Wk.j(l,$CLJS.MF,BX):l,t=$CLJS.n(c)?$CLJS.Wk.j(m,$CLJS.BN,$CLJS.Ve($CLJS.Vk,dX)):m,u=$CLJS.n(e)?$CLJS.Wk.j(t,$CLJS.qQ,AX):t;return dX(u)}catch(v){if(v instanceof Error)throw m=v,$CLJS.gi($CLJS.mE("Error canonicalizing query: {0}",
$CLJS.H([$CLJS.zV(m)])),new $CLJS.h(null,1,[$CLJS.MF,a],null),m);throw v;}};
sla=function(a){a=$CLJS.O(a);var b=$CLJS.J.g(a,$CLJS.MF),c=$CLJS.O(b);b=$CLJS.J.g(c,$CLJS.dG);c=$CLJS.J.g(c,$CLJS.ZE);return $CLJS.y(b)&&$CLJS.y(c)?(b=$CLJS.eg.j($CLJS.bh,$CLJS.vC,$CLJS.y($CLJS.lf($CLJS.Wa,function k(e,f){try{if($CLJS.zd(f)&&3===$CLJS.D(f))try{var l=$CLJS.F(f,0);if($CLJS.he(l,$CLJS.pG)){var m=$CLJS.F(f,1),t=$CLJS.F(f,2);return new $CLJS.P(null,1,5,$CLJS.Q,[new $CLJS.P(null,2,5,$CLJS.Q,[f,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.pG,m,$CLJS.Lk.g(t,$CLJS.vG)],null)],null)],null)}throw $CLJS.Y;
}catch(u){if(u instanceof Error){l=u;if(l===$CLJS.Y)throw $CLJS.Y;throw l;}throw u;}else throw $CLJS.Y;}catch(u){if(u instanceof Error){l=u;if(l===$CLJS.Y)return $CLJS.HV(k,e,f);throw l;}throw u;}}($CLJS.Cf,b)))),$CLJS.DX.j(a,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.MF,$CLJS.ZE],null),$CLJS.Tk.g($CLJS.Df,$CLJS.Ve($CLJS.Uk,b)))):a};
tla=function(a){try{return sla(a)}catch(c){if(c instanceof Error){var b=c;throw $CLJS.gi($CLJS.RE("Error performing whole query transformations"),new $CLJS.h(null,1,[$CLJS.MF,a],null),b);}throw c;}};
ula=function(a,b){var c=$CLJS.eg.g($CLJS.jd(a),function(){return function f(e){return new $CLJS.ne(null,function(){for(var k=e;;)if(k=$CLJS.y(k)){if($CLJS.Ad(k)){var l=$CLJS.lc(k),m=$CLJS.D(l),t=$CLJS.qe(m);return function(){for(var z=0;;)if(z<m){var C=$CLJS.kd(l,z),G=$CLJS.I(C,0,null);C=$CLJS.I(C,1,null);var K=$CLJS.be.g(b,G);C=EX.g?EX.g(C,K):EX.call(null,C,K);null!=C&&t.add(new $CLJS.P(null,2,5,$CLJS.Q,[G,C],null));z+=1}else return!0}()?$CLJS.te($CLJS.ve(t),f($CLJS.mc(k))):$CLJS.te($CLJS.ve(t),
null)}var u=$CLJS.A(k),v=$CLJS.I(u,0,null),x=$CLJS.I(u,1,null);u=function(){var z=x,C=$CLJS.be.g(b,v);return EX.g?EX.g(z,C):EX.call(null,z,C)}();if(null!=u)return $CLJS.ee(new $CLJS.P(null,2,5,$CLJS.Q,[v,u],null),f($CLJS.Lc(k)));k=$CLJS.Lc(k)}else return null},null,null)}(a)}());return $CLJS.y(c)?c:null};vla=function(a,b){a=$CLJS.Vk.g(function(c){var d=$CLJS.be.g(b,FX);return EX.g?EX.g(c,d):EX.call(null,c,d)},a);return $CLJS.n($CLJS.Re($CLJS.Wa,a))?a:null};
ZW=function ZW(a,b){var d=a.exec(b);if(null==d)return null;var e=d[0],f=1===d.length?e:$CLJS.Df(d);return $CLJS.ee(f,new $CLJS.ne(null,function(){var k=e.length;k=d.index+(1>k?1:k);return k<=b.length?(k=b.substring(k),ZW.g?ZW.g(a,k):ZW.call(null,a,k)):null},null,null))};
$CLJS.DX=function DX(a){switch(arguments.length){case 3:return DX.j(arguments[0],arguments[1],arguments[2]);case 4:return DX.v(arguments[0],arguments[1],arguments[2],arguments[3]);case 5:return DX.N(arguments[0],arguments[1],arguments[2],arguments[3],arguments[4]);case 6:return DX.W(arguments[0],arguments[1],arguments[2],arguments[3],arguments[4],arguments[5]);default:for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return DX.l(arguments[0],arguments[1],arguments[2],
arguments[3],arguments[4],arguments[5],new $CLJS.w(c.slice(6),0,null))}};$CLJS.DX.j=function(a,b,c){var d=$CLJS.y(b);b=$CLJS.A(d);if(d=$CLJS.B(d))a=$CLJS.R.j(a,b,$CLJS.DX.j($CLJS.J.g(a,b),d,c));else{d=$CLJS.R.j;var e=$CLJS.J.g(a,b);c=c.h?c.h(e):c.call(null,e);a=d.call($CLJS.R,a,b,c)}return a};
$CLJS.DX.v=function(a,b,c,d){var e=$CLJS.y(b);b=$CLJS.A(e);if(e=$CLJS.B(e))a=$CLJS.R.j(a,b,$CLJS.DX.v($CLJS.J.g(a,b),e,c,d));else{e=$CLJS.R.j;var f=$CLJS.J.g(a,b);c=c.g?c.g(f,d):c.call(null,f,d);a=e.call($CLJS.R,a,b,c)}return a};$CLJS.DX.N=function(a,b,c,d,e){var f=$CLJS.y(b);b=$CLJS.A(f);if(f=$CLJS.B(f))a=$CLJS.R.j(a,b,$CLJS.DX.N($CLJS.J.g(a,b),f,c,d,e));else{f=$CLJS.R.j;var k=$CLJS.J.g(a,b);c=c.j?c.j(k,d,e):c.call(null,k,d,e);a=f.call($CLJS.R,a,b,c)}return a};
$CLJS.DX.W=function(a,b,c,d,e,f){var k=$CLJS.y(b);b=$CLJS.A(k);if(k=$CLJS.B(k))a=$CLJS.R.j(a,b,$CLJS.DX.W($CLJS.J.g(a,b),k,c,d,e,f));else{k=$CLJS.R.j;var l=$CLJS.J.g(a,b);c=c.v?c.v(l,d,e,f):c.call(null,l,d,e,f);a=k.call($CLJS.R,a,b,c)}return a};$CLJS.DX.l=function(a,b,c,d,e,f,k){var l=$CLJS.y(b);b=$CLJS.A(l);return(l=$CLJS.B(l))?$CLJS.R.j(a,b,$CLJS.Se.l($CLJS.DX,$CLJS.J.g(a,b),l,c,d,$CLJS.H([e,f,k]))):$CLJS.R.j(a,b,$CLJS.Se.l(c,$CLJS.J.g(a,b),d,e,f,$CLJS.H([k])))};
$CLJS.DX.B=function(a){var b=$CLJS.A(a),c=$CLJS.B(a);a=$CLJS.A(c);var d=$CLJS.B(c);c=$CLJS.A(d);var e=$CLJS.B(d);d=$CLJS.A(e);var f=$CLJS.B(e);e=$CLJS.A(f);var k=$CLJS.B(f);f=$CLJS.A(k);k=$CLJS.B(k);return this.l(b,a,c,d,e,f,k)};$CLJS.DX.A=6;
var xX=function xX(a,b){return $CLJS.AV($CLJS.Ve(xX,a),$CLJS.Td,a.h?a.h(b):a.call(null,b))},iX=function iX(a){for(;;){if($CLJS.xd(a))return $CLJS.Ft(a,iX);if($CLJS.Dd(a))a=$CLJS.Df(a);else if($CLJS.zd(a)){if($CLJS.Va($CLJS.Re($CLJS.Wa,a)))return null;var c=a,d=$CLJS.y(c),e=$CLJS.A(d),f=$CLJS.B(d),k=e,l=f,m=$CLJS.I(l,0,null),t=l,u=k,v=u instanceof $CLJS.M?u.T:null;switch(v){case "not":if($CLJS.Pl(m)){var x=$CLJS.A(m),z=x instanceof $CLJS.M?x.T:null;switch(z){case "not":a=$CLJS.hd(m);continue;case "and":return jX($CLJS.ys,
$CLJS.hf.g(function(){return function(C){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.As,C],null)}}(a,x,z,u,v,c,d,e,f,k,l,m,t),$CLJS.Lc(m)));case "or":return jX($CLJS.Es,$CLJS.hf.g(function(){return function(C){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.As,C],null)}}(a,x,z,u,v,c,d,e,f,k,l,m,t),$CLJS.Lc(m)));default:return a}}else return a;case "and":return jX($CLJS.Es,t);case "or":return jX($CLJS.ys,t);default:return $CLJS.Vk.g(iX,a)}}else return a}},wla=new $CLJS.M(null,"value_field","value_field",
-980977878),GX=new $CLJS.M(null,"ascending","ascending",-988350486),HX=new $CLJS.M(null,"named","named",-422393479),IX=new $CLJS.M(null,"values_source_config","values_source_config",-590570309),JX=new $CLJS.M(null,"descending","descending",-24766135),KX=new $CLJS.M(null,"datetime-field","datetime-field",21731696),xla=new $CLJS.M(null,"use-as-display-name?","use-as-display-name?",686752941),LX=new $CLJS.M(null,"field-literal","field-literal",-1295883554),MX=new $CLJS.M(null,"viz-settings","viz-settings",
256055379),yla=new $CLJS.M(null,"rows","rows",850049680),zla=new $CLJS.M(null,"special-fn","special-fn",1290649344),FX=new $CLJS.M("metabase.mbql.normalize","sequence","metabase.mbql.normalize/sequence",-1700645683),nX=new $CLJS.M(null,"ignore-path","ignore-path",944069061),Ala=new $CLJS.M(null,"label_field","label_field",-1573182765),Bla=new $CLJS.M(null,"fk-\x3e","fk-\x3e",-499026738),Cla=new $CLJS.M(null,"joined-field","joined-field",-2048778268),mla=new $CLJS.M(null,"visibility_type","visibility_type",
-508434247);var NX,Dla=$CLJS.Xe($CLJS.N),Ela=$CLJS.Xe($CLJS.N),Fla=$CLJS.Xe($CLJS.N),Gla=$CLJS.Xe($CLJS.N),Hla=$CLJS.J.j($CLJS.N,$CLJS.ij,$CLJS.Vh.o?$CLJS.Vh.o():$CLJS.Vh.call(null));NX=new $CLJS.di($CLJS.Fh.g("metabase.mbql.normalize","normalize-mbql-clause-tokens"),$CLJS.Tk.g(bX,$CLJS.A),Hla,Dla,Ela,Fla,Gla);NX.m(null,$CLJS.eG,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);b=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.eG,b],null);return null!=a?$CLJS.be.g(b,a):b});
NX.m(null,$CLJS.tB,function(a){$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.tB,a instanceof $CLJS.M?gX(a):a],null)});NX.m(null,$CLJS.JV,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);return $CLJS.n(a)?$CLJS.be.g(NX.h(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.JV,b,c],null)),a):new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.JV,mX.g?mX.g(b,nX):mX.call(null,b,nX),bX(c)],null)});
NX.m(null,$CLJS.pG,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);var c=mX.g?mX.g(a,nX):mX.call(null,a,nX);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.pG,b,function(){var d=$CLJS.n($CLJS.wB.h(c))?$CLJS.Wk.j(c,$CLJS.wB,$CLJS.zh):c;d=$CLJS.n($CLJS.vG.h(c))?$CLJS.Wk.j(d,$CLJS.vG,$CLJS.zh):d;return $CLJS.n($CLJS.BR.h(c))?$CLJS.Wk.j(d,$CLJS.BR,function(e){return $CLJS.n($CLJS.KE.h(e))?$CLJS.Wk.j(e,$CLJS.KE,$CLJS.zh):e}):d}()],null)});
NX.m(null,LX,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[LX,b instanceof $CLJS.M?gX(b):b,$CLJS.zh.h(a)],null)});NX.m(null,KX,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);return $CLJS.n(a)?new $CLJS.P(null,4,5,$CLJS.Q,[KX,mX.g?mX.g(b,nX):mX.call(null,b,nX),$CLJS.mt,bX(a)],null):new $CLJS.P(null,3,5,$CLJS.Q,[KX,mX.g?mX.g(b,nX):mX.call(null,b,nX),bX(c)],null)});
NX.m(null,$CLJS.BG,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null),d=$CLJS.I(a,3,null);a=$CLJS.I(a,4,null);return $CLJS.n(a)?$CLJS.be.g(NX.h(new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.BG,b,c,d],null)),mX.g?mX.g(a,nX):mX.call(null,a,nX)):new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.BG,mX.g?mX.g(b,nX):mX.call(null,b,nX),$CLJS.Fd(c)?c:bX(c),bX(d)],null)});
NX.m(null,$CLJS.iJ,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return $CLJS.n(a)?new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.iJ,b,bX(a)],null):new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.iJ,$CLJS.Yv],null)});NX.m(null,$CLJS.gJ,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gJ,b,bX(a)],null)});
NX.m(null,$CLJS.qJ,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);return new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.qJ,mX.g?mX.g(b,nX):mX.call(null,b,nX),c,bX(a)],null)});NX.m(null,$CLJS.wJ,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);return new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.wJ,mX.g?mX.g(b,nX):mX.call(null,b,nX),c,bX(a)],null)});
NX.m(null,$CLJS.oJ,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return $CLJS.n(a)?new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.oJ,mX.g?mX.g(b,nX):mX.call(null,b,nX),bX(a)],null):new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.oJ,mX.g?mX.g(b,nX):mX.call(null,b,nX)],null)});
NX.m(null,$CLJS.uJ,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);return $CLJS.n(a)?new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.uJ,mX.g?mX.g(b,nX):mX.call(null,b,nX),bX(c),bX(a)],null):new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.uJ,mX.g?mX.g(b,nX):mX.call(null,b,nX),bX(c)],null)});
NX.m(null,$CLJS.fJ,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);return new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.fJ,mX.g?mX.g(b,nX):mX.call(null,b,nX),mX.g?mX.g(c,nX):mX.call(null,c,nX),bX(a)],null)});NX.m(null,$CLJS.Ej,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ej,b,a],null)});
NX.m(null,$CLJS.ci,function(a){var b=$CLJS.y(a);a=$CLJS.A(b);b=$CLJS.B(b);return $CLJS.eg.j(new $CLJS.P(null,1,5,$CLJS.Q,[bX(a)],null),$CLJS.hf.h(function(c){return mX.g?mX.g(c,nX):mX.call(null,c,nX)}),b)});
var OX=function OX(a){if($CLJS.n($CLJS.EA($CLJS.ge,$CLJS.Ya)(a))){var c=bX(a);var d=new $CLJS.ah(null,new $CLJS.h(null,18,[$CLJS.bo,null,$CLJS.fs,null,$CLJS.EG,null,$CLJS.yG,null,$CLJS.ot,null,$CLJS.qG,null,$CLJS.JG,null,$CLJS.KG,null,$CLJS.VF,null,$CLJS.aG,null,$CLJS.es,null,$CLJS.WF,null,$CLJS.DG,null,$CLJS.wG,null,$CLJS.lk,null,$CLJS.Wx,null,$CLJS.jG,null,$CLJS.CG,null],null),null);c=d.h?d.h(c):d.call(null,c)}else c=null;return $CLJS.n(c)?c:$CLJS.n(kX(a))?(a=$CLJS.A(a),OX.h?OX.h(a):OX.call(null,
a)):null},Ila=new $CLJS.h(null,8,[$CLJS.nj,bX,$CLJS.qQ,function(a){a=cX(a);return $CLJS.y($CLJS.NQ.h(a))?$CLJS.Wk.j(a,$CLJS.NQ,kla):a},$CLJS.MF,new $CLJS.h(null,6,[$CLJS.eG,function PX(a){if($CLJS.n($CLJS.EA($CLJS.ge,$CLJS.Ya)(a)))return bX(a);if($CLJS.n(lX(HX,a))){a=$CLJS.y(a);$CLJS.A(a);var c=$CLJS.B(a);a=$CLJS.A(c);c=$CLJS.B(c);return $CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[HX,PX.h?PX.h(a):PX.call(null,a)],null),c)}return $CLJS.n($CLJS.n(kX(a))?OX($CLJS.hd(a)):null)?$CLJS.Vk.g(PX,a):mX.g?mX.g(a,
nX):mX.call(null,a,nX)},$CLJS.fQ,function(a){return $CLJS.eg.g($CLJS.N,function(){return function d(c){return new $CLJS.ne(null,function(){for(;;){var e=$CLJS.y(c);if(e){if($CLJS.Ad(e)){var f=$CLJS.lc(e),k=$CLJS.D(f),l=$CLJS.qe(k);a:for(var m=0;;)if(m<k){var t=$CLJS.kd(f,m),u=$CLJS.I(t,0,null);t=$CLJS.I(t,1,null);u=new $CLJS.P(null,2,5,$CLJS.Q,[gX(u),mX.g?mX.g(t,nX):mX.call(null,t,nX)],null);l.add(u);m+=1}else{f=!0;break a}return f?$CLJS.te($CLJS.ve(l),d($CLJS.mc(e))):$CLJS.te($CLJS.ve(l),null)}f=
$CLJS.A(e);l=$CLJS.I(f,0,null);f=$CLJS.I(f,1,null);return $CLJS.ee(new $CLJS.P(null,2,5,$CLJS.Q,[gX(l),mX.g?mX.g(f,nX):mX.call(null,f,nX)],null),d($CLJS.Lc(e)))}return null}},null,null)}(a)}())},$CLJS.VR,function(a){return $CLJS.Df(function(){return function d(c){return new $CLJS.ne(null,function(){for(;;){var e=$CLJS.y(c);if(e){if($CLJS.Ad(e)){var f=$CLJS.lc(e),k=$CLJS.D(f),l=$CLJS.qe(k);a:for(var m=0;;)if(m<k){var t=$CLJS.kd(f,m);t=$CLJS.n(kX(t))?NX.h(t):$CLJS.ce(NX.h($CLJS.ce(t)));l.add(t);m+=
1}else{f=!0;break a}return f?$CLJS.te($CLJS.ve(l),d($CLJS.mc(e))):$CLJS.te($CLJS.ve(l),null)}l=$CLJS.A(e);return $CLJS.ee($CLJS.n(kX(l))?NX.h(l):$CLJS.ce(NX.h($CLJS.ce(l))),d($CLJS.Lc(e)))}return null}},null,null)}(a)}())},$CLJS.PQ,function(a){a=cX(a);a=$CLJS.O(a);var b=$CLJS.J.g(a,$CLJS.qQ);if($CLJS.n(b))return a=$CLJS.GV(a,new $CLJS.h(null,1,[$CLJS.qQ,$CLJS.MF],null)),b=new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.qQ],null),a=mX.g?mX.g(a,b):mX.call(null,a,b),$CLJS.GV(a,new $CLJS.h(null,1,[$CLJS.MF,$CLJS.qQ],
null));b=new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.MF],null);return mX.g?mX.g(a,b):mX.call(null,a,b)},$CLJS.YO,new $CLJS.h(null,1,[FX,oX],null),$CLJS.KN,new $CLJS.h(null,1,[FX,function(a){a=mX.g?mX.g(a,$CLJS.MF):mX.call(null,a,$CLJS.MF);var b=$CLJS.O(a),c=$CLJS.J.g(b,$CLJS.KE),d=$CLJS.J.g(b,$CLJS.ZE);a=$CLJS.J.g(b,$CLJS.cF);b=$CLJS.n(c)?$CLJS.Wk.j(b,$CLJS.KE,bX):b;d=$CLJS.n($CLJS.EA($CLJS.ge,$CLJS.Ya)(d))?$CLJS.Wk.j(b,$CLJS.ZE,bX):b;return $CLJS.n(a)?$CLJS.Wk.j(d,$CLJS.cF,gX):d}],null)],null),$CLJS.Ly,
new $CLJS.h(null,1,[$CLJS.vO,$CLJS.Td],null),$CLJS.BN,new $CLJS.h(null,1,[FX,function(a){var b=$CLJS.O(a),c=$CLJS.J.g(b,$CLJS.nj),d=$CLJS.J.g(b,$CLJS.BJ),e=$CLJS.J.g(b,$CLJS.$i);a=$CLJS.J.g(b,IX);b=$CLJS.n(e)?$CLJS.Wk.j(b,$CLJS.$i,gX):b;c=$CLJS.n(c)?$CLJS.Wk.j(b,$CLJS.nj,bX):b;d=$CLJS.n(d)?$CLJS.Wk.j(c,$CLJS.BJ,function(f){return mX.g?mX.g(f,nX):mX.call(null,f,nX)}):c;d=$CLJS.n(a)?$CLJS.DX.j(d,new $CLJS.P(null,2,5,$CLJS.Q,[IX,Ala],null),function(f){return mX.g?mX.g(f,nX):mX.call(null,f,nX)}):d;return $CLJS.n(a)?
$CLJS.DX.j(d,new $CLJS.P(null,2,5,$CLJS.Q,[IX,wla],null),function(f){return mX.g?mX.g(f,nX):mX.call(null,f,nX)}):d}],null),$CLJS.wN,function(a){return null==a?null:bX(a)},$CLJS.YO,new $CLJS.h(null,1,[FX,oX],null),MX,bX],null),mX=function mX(a){for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return mX.l(arguments[0],1<c.length?new $CLJS.w(c.slice(1),0,null):null)};
mX.l=function(a,b){b=$CLJS.I(b,0,null);var c=b instanceof $CLJS.M?new $CLJS.P(null,1,5,$CLJS.Q,[b],null):$CLJS.Df(b);b=$CLJS.y(c)?$CLJS.FA(Ila,c):null;try{return $CLJS.md(b)?b.h?b.h(a):b.call(null,a):$CLJS.yd(a)?a:$CLJS.xd(a)?$CLJS.eg.g($CLJS.N,function(){return function k(f){return new $CLJS.ne(null,function(){for(;;){var l=$CLJS.y(f);if(l){if($CLJS.Ad(l)){var m=$CLJS.lc(l),t=$CLJS.D(m),u=$CLJS.qe(t);a:for(var v=0;;)if(v<t){var x=$CLJS.kd(m,v),z=$CLJS.I(x,0,null);x=$CLJS.I(x,1,null);z=bX(z);z=new $CLJS.P(null,
2,5,$CLJS.Q,[z,mX.l(x,$CLJS.H([$CLJS.be.g($CLJS.Df(c),z)]))],null);u.add(z);v+=1}else{m=!0;break a}return m?$CLJS.te($CLJS.ve(u),k($CLJS.mc(l))):$CLJS.te($CLJS.ve(u),null)}m=$CLJS.A(l);u=$CLJS.I(m,0,null);m=$CLJS.I(m,1,null);u=bX(u);return $CLJS.ee(new $CLJS.P(null,2,5,$CLJS.Q,[u,mX.l(m,$CLJS.H([$CLJS.be.g($CLJS.Df(c),u)]))],null),k($CLJS.Lc(l)))}return null}},null,null)}(a)}()):$CLJS.n(kX(a))?NX.h(a):$CLJS.wd(a)?$CLJS.Vk.g(function(e){return mX.l(e,$CLJS.H([$CLJS.be.g($CLJS.Df(c),FX)]))},a):a}catch(e){if(e instanceof
Error){var d=e;throw $CLJS.gi($CLJS.mE("Error normalizing form: {0}",$CLJS.H([$CLJS.zV(d)])),new $CLJS.h(null,3,[$CLJS.cA,a,$CLJS.tl,c,zla,b],null),d);}throw e;}};mX.A=1;mX.B=function(a){var b=$CLJS.A(a);a=$CLJS.B(a);return this.l(b,a)};
var qX=function(){var a=$CLJS.Xe($CLJS.N),b=$CLJS.Xe($CLJS.N),c=$CLJS.Xe($CLJS.N),d=$CLJS.Xe($CLJS.N),e=$CLJS.J.j($CLJS.N,$CLJS.ij,$CLJS.Vh.o?$CLJS.Vh.o():$CLJS.Vh.call(null));return new $CLJS.di($CLJS.Fh.g("metabase.mbql.normalize","canonicalize-mbql-clause"),function(f){return $CLJS.n(kX(f))?$CLJS.A(f):null},e,a,b,c,d)}();qX.m(null,$CLJS.ci,function(a){return a});
qX.m(null,$CLJS.pG,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);if($CLJS.n(lX($CLJS.pG,b))){$CLJS.I(b,0,null);var c=$CLJS.I(b,1,null);b=$CLJS.I(b,2,null);return qX.h(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.pG,c,$CLJS.Fe($CLJS.kl.l($CLJS.H([b,a])))],null))}return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.pG,b,$CLJS.Fe(a)],null)});
qX.m(null,$CLJS.eG,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return $CLJS.td(a)?new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.eG,b],null):new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.eG,b,a],null)});qX.m(null,$CLJS.bL,function(a){$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return $CLJS.n(kX(a))?qX.h(a):new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.pG,a,null],null)});
qX.m(null,LX,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.pG,b,new $CLJS.h(null,1,[$CLJS.wB,a],null)],null)});qX.m(null,Bla,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);var c=rX(b);$CLJS.I(c,0,null);b=$CLJS.I(c,1,null);$CLJS.I(c,2,null);c=rX(a);$CLJS.I(c,0,null);a=$CLJS.I(c,1,null);c=$CLJS.I(c,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.pG,a,$CLJS.R.j(c,$CLJS.LN,b)],null)});
qX.m(null,Cla,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);a=rX(a);return $CLJS.Se.v($CLJS.OV,a,$CLJS.R,$CLJS.H([$CLJS.AP,b]))});
qX.m(null,KX,function(a){var b=$CLJS.D(a);switch(b){case 3:$CLJS.I(a,0,null);b=$CLJS.I(a,1,null);var c=$CLJS.I(a,2,null);a=rX(b);b=c;$CLJS.I(a,0,null);$CLJS.I(a,1,null);c=$CLJS.I(a,2,null);c=$CLJS.O(c);var d=$CLJS.J.g(c,$CLJS.wB);$CLJS.Va(d)||$CLJS.SS.g(d,b)?a=$CLJS.Se.v($CLJS.OV,a,$CLJS.R,$CLJS.H([$CLJS.vG,b])):(c=$CLJS.OA($CLJS.Jy),$CLJS.n($CLJS.NA("metabase.mbql.util",c))&&(b=$CLJS.mE("{0} is not a valid temporal unit for {1}; not adding to clause {2}",$CLJS.H([b,d,$CLJS.Mh.l($CLJS.H([a]))])),
b instanceof Error?$CLJS.MA("metabase.mbql.util",c,$CLJS.Fw(),b):$CLJS.MA("metabase.mbql.util",c,$CLJS.Fw.l($CLJS.H([b])),null)));return a;case 4:return $CLJS.I(a,0,null),b=$CLJS.I(a,1,null),$CLJS.I(a,2,null),c=$CLJS.I(a,3,null),qX.h(new $CLJS.P(null,3,5,$CLJS.Q,[KX,b,c],null));default:throw Error(["No matching clause: ",$CLJS.p.h(b)].join(""));}});
qX.m(null,$CLJS.JV,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null),d=$CLJS.I(a,3,null);a=$CLJS.I(a,4,null);var e=rX(b);$CLJS.I(e,0,null);b=$CLJS.I(e,1,null);e=$CLJS.I(e,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.pG,b,$CLJS.R.j(e,$CLJS.BR,$CLJS.kl.l($CLJS.H([new $CLJS.h(null,1,[$CLJS.KE,c],null),$CLJS.n(d)?$CLJS.Ee([c,d]):null,a])))],null)});
for(var QX=$CLJS.y(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Es,$CLJS.ys,$CLJS.As],null)),RX=null,SX=0,TX=0;;)if(TX<SX){var UX=RX.X(null,TX);qX.m(null,UX,function(){return function(a){return sX(a)}}(QX,RX,SX,TX,UX));TX+=1}else{var VX=$CLJS.y(QX);if(VX){var WX=VX;if($CLJS.Ad(WX)){var XX=$CLJS.lc(WX),Jla=$CLJS.mc(WX),Kla=XX,Lla=$CLJS.D(XX);QX=Jla;RX=Kla;SX=Lla}else{var YX=$CLJS.A(WX);qX.m(null,YX,function(){return function(a){return sX(a)}}(QX,RX,SX,TX,YX,WX,VX));QX=$CLJS.B(WX);RX=null;SX=0}TX=0}else break}
qX.m(null,$CLJS.YF,function(a){a=$CLJS.y(a);$CLJS.A(a);var b=$CLJS.B(a);a=$CLJS.A(b);var c=$CLJS.B(b);b=$CLJS.A(c);c=$CLJS.B(c);return $CLJS.eg.g(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.YF,rX(a),rX(b)],null),c)});qX.m(null,$CLJS.BG,function(a){a=$CLJS.y(a);$CLJS.A(a);var b=$CLJS.B(a);a=$CLJS.A(b);b=$CLJS.B(b);var c=rX(a);a=$CLJS.n(hX($CLJS.pG,a))?$CLJS.OV.l(c,$CLJS.Lk,$CLJS.H([$CLJS.vG])):c;return $CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.BG,a],null),b)});
for(var ZX=$CLJS.y(new $CLJS.P(null,15,5,$CLJS.Q,[$CLJS.gG,$CLJS.AG,$CLJS.oG,$CLJS.hG,$CLJS.cs,$CLJS.GG,$CLJS.$r,$CLJS.bs,$CLJS.Wr,$CLJS.Yr,$CLJS.TF,$CLJS.$F,$CLJS.RF,$CLJS.bG,$CLJS.SF],null)),$X=null,aY=0,bY=0;;)if(bY<aY){var cY=$X.X(null,bY);qX.m(null,cY,function(){return function(a){return tX(a)}}(ZX,$X,aY,bY,cY));bY+=1}else{var dY=$CLJS.y(ZX);if(dY){var eY=dY;if($CLJS.Ad(eY)){var fY=$CLJS.lc(eY),Mla=$CLJS.mc(eY),Nla=fY,Ola=$CLJS.D(fY);ZX=Mla;$X=Nla;aY=Ola}else{var gY=$CLJS.A(eY);qX.m(null,gY,
function(){return function(a){return tX(a)}}(ZX,$X,aY,bY,gY,eY,dY));ZX=$CLJS.B(eY);$X=null;aY=0}bY=0}else break}qX.m(null,yla,function(){return null});qX.m(null,$CLJS.BM,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.BM,qX.h(b),a],null)});
qX.m(null,HX,function(a){a=$CLJS.y(a);$CLJS.A(a);a=$CLJS.B(a);var b=$CLJS.A(a),c=$CLJS.B(a);a=$CLJS.A(c);var d=$CLJS.B(c);c=qX.h;var e=$CLJS.Q;b=qX.h(b);d=$CLJS.I(d,0,null);d=$CLJS.O(d);a=!1===$CLJS.J.g(d,xla)?new $CLJS.h(null,1,[$CLJS.T,a],null):new $CLJS.h(null,1,[$CLJS.tE,a],null);return c.call(qX,new $CLJS.P(null,3,5,e,[$CLJS.BM,b,a],null))});
for(var hY=$CLJS.y(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Wx,$CLJS.qG],null)),iY=null,jY=0,kY=0;;)if(kY<jY){var lY=iY.X(null,kY);qX.m(null,lY,function(){return function(a){return uX(a)}}(hY,iY,jY,kY,lY));kY+=1}else{var mY=$CLJS.y(hY);if(mY){var nY=mY;if($CLJS.Ad(nY)){var oY=$CLJS.lc(nY),Pla=$CLJS.mc(nY),Qla=oY,Rla=$CLJS.D(oY);hY=Pla;iY=Qla;jY=Rla}else{var pY=$CLJS.A(nY);qX.m(null,pY,function(){return function(a){return uX(a)}}(hY,iY,jY,kY,pY,nY,mY));hY=$CLJS.B(nY);iY=null;jY=0}kY=0}else break}
for(var qY=$CLJS.y(new $CLJS.P(null,9,5,$CLJS.Q,[$CLJS.jG,$CLJS.LG,$CLJS.VF,$CLJS.EG,$CLJS.CG,$CLJS.bo,$CLJS.lk,$CLJS.DG,$CLJS.WF],null)),rY=null,sY=0,tY=0;;)if(tY<sY){var uY=rY.X(null,tY);qX.m(null,uY,function(){return function(a){return vX(a)}}(qY,rY,sY,tY,uY));tY+=1}else{var vY=$CLJS.y(qY);if(vY){var wY=vY;if($CLJS.Ad(wY)){var xY=$CLJS.lc(wY),Sla=$CLJS.mc(wY),Tla=xY,Ula=$CLJS.D(xY);qY=Sla;rY=Tla;sY=Ula}else{var yY=$CLJS.A(wY);qX.m(null,yY,function(){return function(a){return vX(a)}}(qY,rY,sY,tY,
yY,wY,vY));qY=$CLJS.B(wY);rY=null;sY=0}tY=0}else break}qX.m(null,$CLJS.aG,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.aG,rX(b),a],null)});
for(var zY=$CLJS.y(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.wG,$CLJS.yG],null)),AY=null,BY=0,CY=0;;)if(CY<BY){var DY=AY.X(null,CY);qX.m(null,DY,function(){return function(a){return wX(a)}}(zY,AY,BY,CY,DY));CY+=1}else{var EY=$CLJS.y(zY);if(EY){var FY=EY;if($CLJS.Ad(FY)){var GY=$CLJS.lc(FY),Vla=$CLJS.mc(FY),Wla=GY,Xla=$CLJS.D(GY);zY=Vla;AY=Wla;BY=Xla}else{var HY=$CLJS.A(FY);qX.m(null,HY,function(){return function(a){return wX(a)}}(zY,AY,BY,CY,HY,FY,EY));zY=$CLJS.B(FY);AY=null;BY=0}CY=0}else break}
qX.m(null,$CLJS.JG,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.JG,qX.h(b),qX.h(a)],null)});
qX.m(null,$CLJS.ZF,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return $CLJS.n(a)?$CLJS.be.g(qX.h(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ZF,b],null)),mX.l(a,$CLJS.H([nX]))):new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ZF,$CLJS.Df(function(){return function e(d){return new $CLJS.ne(null,function(){for(;;){var f=$CLJS.y(d);if(f){if($CLJS.Ad(f)){var k=$CLJS.lc(f),l=$CLJS.D(k),m=$CLJS.qe(l);a:for(var t=0;;)if(t<l){var u=$CLJS.kd(k,t),v=$CLJS.I(u,0,null);u=$CLJS.I(u,1,null);v=new $CLJS.P(null,
2,5,$CLJS.Q,[qX.h(v),qX.h(u)],null);m.add(v);t+=1}else{k=!0;break a}return k?$CLJS.te($CLJS.ve(m),e($CLJS.mc(f))):$CLJS.te($CLJS.ve(m),null)}k=$CLJS.A(f);m=$CLJS.I(k,0,null);k=$CLJS.I(k,1,null);return $CLJS.ee(new $CLJS.P(null,2,5,$CLJS.Q,[qX.h(m),qX.h(k)],null),e($CLJS.Lc(f)))}return null}},null,null)}(b)}())],null)});
qX.m(null,$CLJS.IF,function(a){a=$CLJS.y(a);$CLJS.A(a);var b=$CLJS.B(a);a=$CLJS.A(b);var c=$CLJS.B(b);b=$CLJS.A(c);c=$CLJS.B(c);return $CLJS.eg.g(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.IF,qX.h(a),$CLJS.E.g(0,b)?1:qX.h(b)],null),$CLJS.hf.g(qX,c))});
var yX=function yX(a){return function f(d,e){try{if($CLJS.n(function(){var x=eX($CLJS.Dd);return x.h?x.h(e):x.call(null,e)}()))return f(d,$CLJS.Df(e));throw $CLJS.Y;}catch(x){if(x instanceof Error)if(d=x,d===$CLJS.Y)try{if($CLJS.n(function(){var z=eX($CLJS.ge);return z.h?z.h(e):z.call(null,e)}()))return new $CLJS.P(null,1,5,$CLJS.Q,[new $CLJS.P(null,1,5,$CLJS.Q,[e],null)],null);throw $CLJS.Y;}catch(z){if(z instanceof Error)if(d=z,d===$CLJS.Y)try{if($CLJS.zd(e)&&2<=$CLJS.D(e))try{var k=$CLJS.$k.j(e,
0,2);if($CLJS.zd(k)&&2===$CLJS.D(k))try{var l=$CLJS.F(k,0);if($CLJS.n($CLJS.kE($CLJS.ge,$CLJS.Te(new $CLJS.ah(null,new $CLJS.h(null,5,[$CLJS.fs,null,$CLJS.ot,null,$CLJS.KG,null,$CLJS.es,null,HX,null],null),null)))(l)))try{var m=$CLJS.F(k,1);if($CLJS.n(OX(m)))return $CLJS.eg.j($CLJS.Cf,$CLJS.lE(yX),a);throw $CLJS.Y;}catch(C){if(C instanceof Error){var t=C;if(t===$CLJS.Y)throw $CLJS.Y;throw t;}throw C;}else throw $CLJS.Y;}catch(C){if(C instanceof Error){t=C;if(t===$CLJS.Y)throw $CLJS.Y;throw t;}throw C;
}else throw $CLJS.Y;}catch(C){if(C instanceof Error){t=C;if(t===$CLJS.Y)throw $CLJS.Y;throw t;}throw C;}else throw $CLJS.Y;}catch(C){if(C instanceof Error)if(t=C,t===$CLJS.Y)try{if($CLJS.zd(e)&&1<=$CLJS.D(e))try{var u=$CLJS.$k.j(e,0,1);if($CLJS.zd(u)&&1===$CLJS.D(u))try{if($CLJS.F(u,0)instanceof $CLJS.M)return new $CLJS.P(null,1,5,$CLJS.Q,[e],null);throw $CLJS.Y;}catch(G){if(G instanceof Error){var v=G;if(v===$CLJS.Y)throw $CLJS.Y;throw v;}throw G;}else throw $CLJS.Y;}catch(G){if(G instanceof Error){v=
G;if(v===$CLJS.Y)throw $CLJS.Y;throw v;}throw G;}else throw $CLJS.Y;}catch(G){if(G instanceof Error){v=G;if(v===$CLJS.Y)return e;throw v;}throw G;}else throw t;else throw C;}else throw d;else throw z;}else throw d;else throw x;}}($CLJS.Cf,a)},IY=function IY(a){return function f(d,e){try{var k=eX($CLJS.Dd);var l=k.h?k.h(e):k.call(null,e);if($CLJS.n(l))return f(d,$CLJS.Df(e));throw $CLJS.Y;}catch(C){if(C instanceof Error)if(l=C,l===$CLJS.Y)try{if($CLJS.zd(e)&&2===$CLJS.D(e))try{var m=$CLJS.F(e,1);if($CLJS.he(m,
$CLJS.mG)){var t=$CLJS.F(e,0);return f(d,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.mG,t],null))}throw $CLJS.Y;}catch(G){if(G instanceof Error)if(l=G,l===$CLJS.Y)try{m=$CLJS.F(e,1);if($CLJS.he(m,$CLJS.fG))return t=$CLJS.F(e,0),f(d,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fG,t],null));throw $CLJS.Y;}catch(K){if(K instanceof Error){var u=K;if(u===$CLJS.Y)try{m=$CLJS.F(e,1);if($CLJS.he(m,GX))return t=$CLJS.F(e,0),f(d,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.mG,t],null));throw $CLJS.Y;}catch(S){if(S instanceof Error)if(l=
S,l===$CLJS.Y)try{m=$CLJS.F(e,1);if($CLJS.he(m,JX))return t=$CLJS.F(e,0),f(d,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fG,t],null));throw $CLJS.Y;}catch(V){if(V instanceof Error)if(m=V,m===$CLJS.Y)try{var v=$CLJS.F(e,0);if($CLJS.he(v,GX))return t=$CLJS.F(e,1),f(d,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.mG,t],null));throw $CLJS.Y;}catch(Z){if(Z instanceof Error)if(m=Z,m===$CLJS.Y)try{v=$CLJS.F(e,0);if($CLJS.he(v,JX))return t=$CLJS.F(e,1),f(d,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fG,t],null));throw $CLJS.Y;}catch(ha){if(ha instanceof
Error)if(m=ha,m===$CLJS.Y)try{v=$CLJS.F(e,0);if($CLJS.he(v,$CLJS.mG))return t=$CLJS.F(e,1),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.mG,rX(t)],null);throw $CLJS.Y;}catch(ra){if(ra instanceof Error)if(m=ra,m===$CLJS.Y)try{v=$CLJS.F(e,0);if($CLJS.he(v,$CLJS.fG))return t=$CLJS.F(e,1),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fG,rX(t)],null);throw $CLJS.Y;}catch(Na){if(Na instanceof Error){t=Na;if(t===$CLJS.Y)throw $CLJS.Y;throw t;}throw Na;}else throw m;else throw ra;}else throw m;else throw ha;}else throw m;else throw Z;
}else throw m;else throw V;}else throw l;else throw S;}else throw u;}else throw K;}else throw l;else throw G;}else throw $CLJS.Y;}catch(G){if(G instanceof Error)if(l=G,l===$CLJS.Y)try{if($CLJS.zd(e)&&0<=$CLJS.D(e))try{var x=$CLJS.$k.j(e,0,0);if($CLJS.zd(x)&&0===$CLJS.D(x))try{var z=$CLJS.$k.g(e,0);if($CLJS.E.g(z,a))return $CLJS.Df($CLJS.nl.h($CLJS.hf.g(IY,a)));throw $CLJS.Y;}catch(K){if(K instanceof Error){u=K;if(u===$CLJS.Y)throw $CLJS.Y;throw u;}throw K;}else throw $CLJS.Y;}catch(K){if(K instanceof
Error){u=K;if(u===$CLJS.Y)throw $CLJS.Y;throw u;}throw K;}else throw $CLJS.Y;}catch(K){if(K instanceof Error){u=K;if(u===$CLJS.Y)return $CLJS.fX(f,d,e);throw u;}throw K;}else throw l;else throw G;}else throw l;else throw C;}}($CLJS.Cf,a)},BX=$CLJS.Tk.g(dX,function(a){var b=CX($CLJS.eG.h(a))?$CLJS.Wk.j(a,$CLJS.eG,nla):a;b=CX($CLJS.dG.h(a))?$CLJS.Wk.j(b,$CLJS.dG,ola):b;b=CX($CLJS.ZE.h(a))?$CLJS.Wk.j(b,$CLJS.ZE,$CLJS.Ve($CLJS.Vk,pX)):b;b=CX($CLJS.VR.h(a))?$CLJS.Wk.j(b,$CLJS.VR,IY):b;return CX($CLJS.PQ.h(a))?
$CLJS.Wk.j(b,$CLJS.PQ,qla):b}),Yla=new $CLJS.h(null,3,[$CLJS.qQ,$CLJS.Td,$CLJS.MF,new $CLJS.h(null,2,[$CLJS.PQ,function(a){a=$CLJS.O(a);var b=$CLJS.J.g(a,$CLJS.qQ);if($CLJS.n(b))return a=$CLJS.GV(a,new $CLJS.h(null,1,[$CLJS.qQ,$CLJS.MF],null)),b=new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.qQ],null),a=EX.g?EX.g(a,b):EX.call(null,a,b),$CLJS.GV(a,new $CLJS.h(null,1,[$CLJS.MF,$CLJS.qQ],null));b=new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.MF],null);return EX.g?EX.g(a,b):EX.call(null,a,b)},$CLJS.KN,new $CLJS.h(null,1,
[FX,function(a){var b=new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.MF],null);return EX.g?EX.g(a,b):EX.call(null,a,b)}],null)],null),MX,$CLJS.Td],null),EX=function EX(a){switch(arguments.length){case 1:return EX.h(arguments[0]);case 2:return EX.g(arguments[0],arguments[1]);default:throw Error(["Invalid arity: ",$CLJS.p.h(arguments.length)].join(""));}};EX.h=function(a){return EX.g(a,$CLJS.Cf)};
EX.g=function(a,b){try{var c=$CLJS.y(b)?$CLJS.FA(Yla,b):null;return $CLJS.md(c)?c.h?c.h(a):c.call(null,a):$CLJS.yd(a)?a:$CLJS.xd(a)?ula(a,b):$CLJS.wd(a)?vla(a,b):a}catch(d){if(d instanceof Error)throw $CLJS.gi("Error removing empty clauses from form.",new $CLJS.h(null,2,[$CLJS.cA,a,$CLJS.tl,b],null),d);throw d;}};EX.A=2;
$CLJS.JY=function(){var a=$CLJS.Tk.l(EX,tla,rla,$CLJS.H([mX]));return function(b){try{return a(b)}catch(d){if(d instanceof Error){var c=d;throw $CLJS.gi($CLJS.mE("Error normalizing query: {0}",$CLJS.H([$CLJS.zV(c)])),new $CLJS.h(null,1,[$CLJS.MF,b],null),c);}throw d;}}}();$CLJS.KY=function KY(a,b){if($CLJS.Va($CLJS.y(a)))a=(0,$CLJS.JY)(b);else{var d=$CLJS.J,e=d.g;var f=$CLJS.Dt(a);b=$CLJS.Ee([$CLJS.id(a),b]);f=KY.g?KY.g(f,b):KY.call(null,f,b);a=e.call(d,f,$CLJS.id(a))}return a};