var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./malli.core.js");require("./medley.core.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.mbql_clause.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.types.js");require("./metabase.util.malli.registry.js");
'use strict';var DH,EH,GH,HH,Jfa,Kfa,Lfa,IH,FH;$CLJS.BH=function(a,b){return $CLJS.gc($CLJS.fb(function(c,d){var e=a.h?a.h(d):a.call(null,d);return $CLJS.hg.j(c,e,$CLJS.be.g($CLJS.J.j(c,e,$CLJS.Cf),d))},$CLJS.ec($CLJS.N),b))};$CLJS.CH=function(a,b){return $CLJS.fb(function(c,d){return $CLJS.n(a.h?a.h(d):a.call(null,d))?$CLJS.Sc(d):null},null,b)};
DH=function(a,b){$CLJS.I(a,0,null);$CLJS.I(a,1,null);$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);b=$CLJS.wC(b,$CLJS.XC)?$CLJS.mH:$CLJS.wC(b,$CLJS.WD)?$CLJS.hH:$CLJS.wC(b,$CLJS.wD)?$CLJS.jH:null;return $CLJS.n(b)?$CLJS.wF(b,a):!0};
EH=function(a){a=$CLJS.y(a);$CLJS.A(a);a=$CLJS.B(a);$CLJS.A(a);a=$CLJS.B(a);a=$CLJS.BH(function(d){return $CLJS.wC($CLJS.UE(d),$CLJS.hD)},a);var b=$CLJS.O(a);a=$CLJS.J.g(b,!1);b=$CLJS.J.g(b,!0);if($CLJS.Rk.g($CLJS.D(a),1))return"Temporal arithmetic expression must contain exactly one non-interval value";if(1>$CLJS.D(b))return"Temporal arithmetic expression must contain at least one :interval";var c=$CLJS.UE($CLJS.A(a));return $CLJS.Re(function(d){$CLJS.I(d,0,null);$CLJS.I(d,1,null);$CLJS.I(d,2,null);
var e=$CLJS.I(d,3,null);return $CLJS.n(DH(d,c))?null:["Cannot add a ",$CLJS.p.h(e)," interval to a ",$CLJS.p.h(c)," expression"].join("")},b)};
GH=function(a){return new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Es,new $CLJS.h(null,1,[$CLJS.vt,[$CLJS.p.h(a)," clause with a temporal expression and one or more :interval clauses"].join("")],null),new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.Gj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cs,a],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.DE],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ms,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,FH],null)],
null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.bF],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ms,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,FH],null)],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Si,new $CLJS.h(null,1,[$CLJS.xt,function(b){b=$CLJS.O(b);b=$CLJS.J.g(b,$CLJS.Ej);return["Invalid ",$CLJS.p.h(a)," clause: ",$CLJS.p.h(EH(b))].join("")}],null),$CLJS.Te(EH)],null)],null)};
HH=function(a){return new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Gj,new $CLJS.h(null,1,[$CLJS.vt,[$CLJS.p.h(a)," clause with numeric args"].join("")],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cs,a],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.DE],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ms,new $CLJS.h(null,1,[$CLJS.bo,2],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.pF],null)],null)],null)],null)};
Jfa=function(a){return $CLJS.Qd($CLJS.zF,$CLJS.hf.g(function(b){var c=$CLJS.UE(b),d=$CLJS.wC(c,$CLJS.fF);b=d?$CLJS.wF($CLJS.tG,b):d;return $CLJS.n(b)?$CLJS.Qj:c},a))};Kfa=function(a){a=$CLJS.CH(function(b){return!$CLJS.wC(b,$CLJS.hD)},$CLJS.hf.g($CLJS.UE,a));return $CLJS.wC(a,$CLJS.fF)?$CLJS.Xj:a};
Lfa=function(a,b){return $CLJS.n($CLJS.Re(function(c){return $CLJS.wC($CLJS.UE(c),$CLJS.hD)},b))?Kfa(b):$CLJS.E.g(a,$CLJS.ot)&&$CLJS.E.g($CLJS.D(b),2)&&($CLJS.Qe(function(c){return $CLJS.wC($CLJS.UE(c),$CLJS.XC)},b)||$CLJS.Qe(function(c){return $CLJS.wC($CLJS.UE(c),$CLJS.wD)},b))?$CLJS.hD:Jfa(b)};IH=new $CLJS.M("metabase.lib.schema.expression.arithmetic","args.numbers","metabase.lib.schema.expression.arithmetic/args.numbers",-179216621);
$CLJS.JH=new $CLJS.M("lib.type-of","type-is-type-of-arithmetic-args","lib.type-of/type-is-type-of-arithmetic-args",-626871615);FH=new $CLJS.M("mbql.clause","interval","mbql.clause/interval",-990446354);$CLJS.X(new $CLJS.M("metabase.lib.schema.expression.arithmetic","args.temporal","metabase.lib.schema.expression.arithmetic/args.temporal",1338604923),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Es,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.qs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.$E,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.bF],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[new $CLJS.M(null,"intervals","intervals",2096054013),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.es,new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.W,FH],null)],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Si,new $CLJS.h(null,1,[$CLJS.vt,"Temporal arithmetic expression with valid interval units for the expression type"],null),function(a){var b=$CLJS.y(a);a=$CLJS.A(b);b=$CLJS.B(b);var c=$CLJS.UE(a);return $CLJS.Qe(function(d){return DH(d,c)},b)}],null)],null));
$CLJS.X(IH,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ms,new $CLJS.h(null,1,[$CLJS.bo,2],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.pF],null)],null)],null));
var Mfa=new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.Gj,new $CLJS.h(null,1,[$CLJS.vt,":- clause taking the difference of two temporal expressions"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cs,$CLJS.ot],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.DE],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.bF],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.bF],null)],null)],
null);$CLJS.PG.g($CLJS.es,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ys,GH($CLJS.es),HH($CLJS.es)],null));$CLJS.PG.g($CLJS.ot,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ys,GH($CLJS.ot),Mfa,HH($CLJS.ot)],null));$CLJS.GF($CLJS.fs,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.at,IH],null)]));$CLJS.GF($CLJS.KG,$CLJS.H([$CLJS.ot,$CLJS.TD,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.at,IH],null)]));
for(var KH=$CLJS.y(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.es,$CLJS.ot,$CLJS.fs],null)),LH=null,MH=0,NH=0;;)if(NH<MH){var Nfa=LH.X(null,NH);$CLJS.xF(Nfa,$CLJS.JH);NH+=1}else{var OH=$CLJS.y(KH);if(OH){var PH=OH;if($CLJS.Ad(PH)){var QH=$CLJS.lc(PH),Ofa=$CLJS.mc(PH),Pfa=QH,Qfa=$CLJS.D(QH);KH=Ofa;LH=Pfa;MH=Qfa}else{var Rfa=$CLJS.A(PH);$CLJS.xF(Rfa,$CLJS.JH);KH=$CLJS.B(PH);LH=null;MH=0}NH=0}else break}
$CLJS.TE.m(null,$CLJS.JH,function(a){var b=$CLJS.y(a);a=$CLJS.A(b);b=$CLJS.B(b);$CLJS.A(b);b=$CLJS.B(b);return Lfa(a,b)});$CLJS.EF($CLJS.QF,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.pF],null)],null)]));$CLJS.xF($CLJS.QF,$CLJS.jF);
for(var RH=$CLJS.y(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.MG,$CLJS.HF,$CLJS.cG],null)),SH=null,TH=0,UH=0;;)if(UH<TH){var Sfa=SH.X(null,UH);$CLJS.EF(Sfa,$CLJS.H([$CLJS.ot,$CLJS.TD,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.pF],null)],null)]));UH+=1}else{var VH=$CLJS.y(RH);if(VH){var WH=VH;if($CLJS.Ad(WH)){var XH=$CLJS.lc(WH),Tfa=$CLJS.mc(WH),Ufa=XH,Vfa=$CLJS.D(XH);RH=Tfa;SH=Ufa;TH=Vfa}else{var Wfa=$CLJS.A(WH);$CLJS.EF(Wfa,$CLJS.H([$CLJS.ot,$CLJS.TD,new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.qk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.pF],null)],null)]));RH=$CLJS.B(WH);SH=null;TH=0}UH=0}else break}
for(var YH=$CLJS.y(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.LF,$CLJS.nG,$CLJS.uG],null)),ZH=null,$H=0,aI=0;;)if(aI<$H){var Xfa=ZH.X(null,aI);$CLJS.EF(Xfa,$CLJS.H([$CLJS.ot,$CLJS.xj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.pF],null)],null)]));aI+=1}else{var bI=$CLJS.y(YH);if(bI){var cI=bI;if($CLJS.Ad(cI)){var dI=$CLJS.lc(cI),Yfa=$CLJS.mc(cI),Zfa=dI,$fa=$CLJS.D(dI);YH=Yfa;ZH=Zfa;$H=$fa}else{var aga=$CLJS.A(cI);$CLJS.EF(aga,$CLJS.H([$CLJS.ot,$CLJS.xj,new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.qk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.pF],null)],null)]));YH=$CLJS.B(cI);ZH=null;$H=0}aI=0}else break}$CLJS.EF($CLJS.NF,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.pF],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.pF],null)],null)]));
$CLJS.TE.m(null,$CLJS.NF,function(a){$CLJS.I(a,0,null);$CLJS.I(a,1,null);var b=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);return $CLJS.wC($CLJS.UE(b),$CLJS.xj)&&$CLJS.wC($CLJS.UE(a),$CLJS.xj)?$CLJS.xj:$CLJS.TD});