var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.schema.common.js");require("./metabase.shared.util.i18n.js");require("./metabase.types.js");require("./metabase.util.malli.js");require("./metabase.util.malli.registry.js");
'use strict';var SE,XE,Yea,Zea,$ea,afa,bfa,cfa;
$CLJS.QE=function(){function a(){return Math.floor(16*Math.random()).toString(16)}var b=(8|3&Math.floor(16*Math.random())).toString(16);return new $CLJS.lA([$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),"-",$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),"-4",$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),"-",$CLJS.p.h(b),$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),"-",$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),
$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a())].join("").toLowerCase())};$CLJS.RE=function(a){for(var b=[],c=arguments.length,d=0;;)if(d<c)b.push(arguments[d]),d+=1;else break;return $CLJS.mE(arguments[0],1<b.length?new $CLJS.w(b.slice(1),0,null):null)};SE=function(a){return $CLJS.zd(a)&&$CLJS.A(a)instanceof $CLJS.M};
$CLJS.UE=function(a){var b=function(){var c=SE(a);return c?(c=$CLJS.xd($CLJS.hd(a)))?(c=$CLJS.Gi.h($CLJS.hd(a)),$CLJS.n(c)?c:$CLJS.wB.h($CLJS.hd(a))):c:c}();return $CLJS.n(b)?b:$CLJS.TE.h(a)};
XE=function(a,b){return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Es,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ys,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Si,new $CLJS.h(null,2,[$CLJS.vt,"valid MBQL clause",$CLJS.xt,function(c){c=$CLJS.O(c);c=$CLJS.J.g(c,$CLJS.Ej);return["invalid MBQL clause: ",$CLJS.Mh.l($CLJS.H([c]))].join("")}],null),$CLJS.Te(SE)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.VE],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Si,new $CLJS.h(null,1,[$CLJS.vt,b],null),function(c){c=$CLJS.UE(c);
return WE(c,a)}],null)],null)};$CLJS.YE=new $CLJS.M(null,"right-join","right-join",-56349359);Yea=new $CLJS.M("metabase.lib.schema.expression","datetime","metabase.lib.schema.expression/datetime",-51960022);Zea=new $CLJS.M("metabase.lib.schema.expression","base-type","metabase.lib.schema.expression/base-type",578343967);$ea=new $CLJS.M("metabase.lib.schema.expression","non-integer-real","metabase.lib.schema.expression/non-integer-real",-1855491016);$CLJS.ZE=new $CLJS.M(null,"fields","fields",-1932066230);
$CLJS.$E=new $CLJS.M(null,"expr","expr",745722291);$CLJS.aF=new $CLJS.M("metabase.lib.schema.expression","expressions","metabase.lib.schema.expression/expressions",790922225);$CLJS.bF=new $CLJS.M("metabase.lib.schema.expression","temporal","metabase.lib.schema.expression/temporal",320116754);$CLJS.cF=new $CLJS.M(null,"alias","alias",-2039751630);$CLJS.dF=new $CLJS.M("metabase.lib.schema.expression","boolean","metabase.lib.schema.expression/boolean",-1396506592);
$CLJS.eF=new $CLJS.M("metabase.lib.schema.expression","expression","metabase.lib.schema.expression/expression",-1389098704);$CLJS.fF=new $CLJS.M("metabase.lib.schema.expression","type.unknown","metabase.lib.schema.expression/type.unknown",-98159107);$CLJS.gF=new $CLJS.M("mbql.clause","field","mbql.clause/field",1497292735);afa=new $CLJS.M("metabase.lib.schema.expression","date","metabase.lib.schema.expression/date",1943847782);$CLJS.hF=new $CLJS.M(null,"left-join","left-join",-672831855);
$CLJS.iF=new $CLJS.M("metabase.lib.schema.expression","equality-comparable","metabase.lib.schema.expression/equality-comparable",-658449046);$CLJS.jF=new $CLJS.M("lib.type-of","type-is-type-of-first-arg","lib.type-of/type-is-type-of-first-arg",-317600808);$CLJS.kF=new $CLJS.M("metabase.lib.schema.expression","orderable","metabase.lib.schema.expression/orderable",-1555566130);bfa=new $CLJS.r("metabase.lib.schema.expression","type-of","metabase.lib.schema.expression/type-of",-1625245814,null);
cfa=new $CLJS.M("metabase.lib.schema.expression","time","metabase.lib.schema.expression/time",865466446);$CLJS.lF=new $CLJS.M(null,"inner-join","inner-join",659431740);$CLJS.mF=new $CLJS.M("metabase.lib.schema.expression","emptyable","metabase.lib.schema.expression/emptyable",941775581);$CLJS.VE=new $CLJS.M("metabase.lib.schema.mbql-clause","clause","metabase.lib.schema.mbql-clause/clause",955279388);
$CLJS.nF=new $CLJS.M("metabase.lib.schema.expression","string","metabase.lib.schema.expression/string",1750008170);$CLJS.oF=new $CLJS.M(null,"full-join","full-join",1305476385);$CLJS.pF=new $CLJS.M("metabase.lib.schema.expression","number","metabase.lib.schema.expression/number",779948930);$CLJS.qF=new $CLJS.M("metabase.lib.schema.expression","integer","metabase.lib.schema.expression/integer",2060430870);$CLJS.TE=function(){var a=$CLJS.Xe($CLJS.N),b=$CLJS.Xe($CLJS.N),c=$CLJS.Xe($CLJS.N),d=$CLJS.Xe($CLJS.N),e=$CLJS.J.j(new $CLJS.h(null,1,[$CLJS.ij,$CLJS.kC],null),$CLJS.ij,$CLJS.Vh.o?$CLJS.Vh.o():$CLJS.Vh.call(null));return new $CLJS.di($CLJS.Fh.g("metabase.lib.schema.expression","type-of-method"),function(f){var k=$CLJS.iC(f);return $CLJS.E.g(k,$CLJS.vB)?$CLJS.ab(f):k},e,a,b,c,d)}();
$CLJS.X(Zea,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ys,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cs,$CLJS.fF],null),$CLJS.IE],null));$CLJS.TE.m(null,$CLJS.ci,function(a){throw $CLJS.hi($CLJS.mE("{0}: Don''t know how to determine the type of {1}",$CLJS.H([bfa,$CLJS.Mh.l($CLJS.H([a]))])),new $CLJS.h(null,1,[$CLJS.$E,a],null));});$CLJS.TE.m(null,$CLJS.jF,function(a){$CLJS.I(a,0,null);$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return $CLJS.UE(a)});
var WE=function WE(a,b){return $CLJS.vd(a)?$CLJS.Re(function(d){return WE.g?WE.g(d,b):WE.call(null,d,b)},a):$CLJS.vd(b)?$CLJS.Re(function(d){return WE.g?WE.g(a,d):WE.call(null,a,d)},b):$CLJS.E.g(a,$CLJS.fF)?!0:$CLJS.wC(a,b)};$CLJS.X($CLJS.dF,XE($CLJS.Mj,"expression returning a boolean"));$CLJS.X($CLJS.nF,XE($CLJS.fk,"expression returning a string"));$CLJS.X($CLJS.qF,XE($CLJS.xj,"expression returning an integer"));$CLJS.X($ea,XE($CLJS.TD,"expression returning a non-integer real number"));
$CLJS.X($CLJS.pF,XE($CLJS.Qj,"expression returning a number"));$CLJS.X(afa,XE($CLJS.XC,"expression returning a date"));$CLJS.X(cfa,XE($CLJS.WD,"expression returning a time"));$CLJS.X(Yea,XE($CLJS.wD,"expression returning a date time"));$CLJS.X($CLJS.bF,XE($CLJS.Xj,"expression returning a date, time, or date time"));$CLJS.rF=new $CLJS.ah(null,new $CLJS.h(null,4,[$CLJS.Qj,null,$CLJS.fk,null,$CLJS.Xj,null,$CLJS.Mj,null],null),null);$CLJS.X($CLJS.kF,XE($CLJS.rF,"an expression that can be compared with :\x3e or :\x3c"));
var dfa=new $CLJS.ah(null,new $CLJS.h(null,8,[$CLJS.GC,null,$CLJS.Qj,null,$CLJS.fk,null,$CLJS.mD,null,$CLJS.Xj,null,$CLJS.Bj,null,$CLJS.Mj,null,$CLJS.RD,null],null),null);$CLJS.X($CLJS.mF,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ys,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.nF],null),XE($CLJS.mD,"expression returning a BSONID")],null));$CLJS.X($CLJS.iF,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xs,XE(dfa,"an expression that can appear in :\x3d or :!\x3d")],null));
$CLJS.X($CLJS.eF,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xs,XE($CLJS.Bj,"any type of expression")],null));
$CLJS.X($CLJS.aF,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Aj,new $CLJS.h(null,1,[$CLJS.bo,1],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Es,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.eF],null),new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Gj,$CLJS.Ms,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.kk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xE,$CLJS.ak],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fs,$CLJS.Ms],null)],null)],null)],null));